import { createContext, useContext, useState } from "react";
const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRecuperar, setIsRecuperar] = useState(false);
  const [usuario_id, setUsuario_id] = useState();
  const headerText = new Headers();
  headerText.append( "Content-Type", "application/json;charset=UTF-8" )
  //DESCOMENTAR PARA LOCAL
  const [baseUrl, setBaseUrl] = useState(
    "https://proveedores.aza.cl/proveedores.service/rest/"
    // "http://localhost:8080/proveedores.service/rest/"
  );
  //DESCOMENTAR PARA LOCAL

  // DESCOMENTAR PARA BETA
  //    const [baseUrl, setBaseUrl] = useState(
  //    "https://adquisiciones.kesp.cl/adquisiciones.prime.service/rest/"
  //  );
  // DESCOMENTAR PARA BETA

  //DESCOMENTAR PARA PRD
  // const [baseUrl, setBaseUrl] = useState(
  // "https://adquisiciones.kesp.cl/adquisiciones.prime.prd.service/rest/"
  // );
  //DESCOMENTAR PARA PRD

  return (
    <GlobalContext.Provider
      value={{
        baseUrl,
        setBaseUrl,
        isLoggedIn,
        setIsLoggedIn,
        usuario_id,
        setUsuario_id,
        isRecuperar,
        setIsRecuperar,
        headerText,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
