import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { useGlobalContext } from "../Config";

const Proveedores = () => {

    const { baseUrl } = useGlobalContext();
    const { headerText } = useGlobalContext();
    
    let emptyProveedor = {
        id: null,
        cuenta: '',
        rut: '',
        razonSocial: '',
        correo: '',
        contacto: '', 
        contrasena: ''
    };

    let emptyUsuario = {
        id: null,
        nombre: '',
        apellido: '',
        usuario: '',
        contrasena: '',
        perfil: {
            id: 0
        }
    };

    const [proveedores, setProveedores] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [proveedorDialog, setProveedorDialog] = useState(false);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteProveedorDialog, setDeleteProveedorDialog] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [proveedor, setProveedor] = useState(emptyProveedor);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [showPassword, setShowPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [usuariosProveedorDialog, setUsuariosProveedorDialog] = useState(false);
    const [perfiles, setPerfiles] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getProveedores();
        getPerfiles();
    }, []);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const getProveedores = async () => {
        const endpoint = baseUrl + "proveedores/list";
        try {
            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText,
            }).then((res) => res.json());
    
            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });                
                throw new Error("Failed to fetch data");
            }
            setProveedores(data.proveedores);
        } 
        catch (error) {
            console.error("Error", error.message);
        }
    };

    const getUsuarios = async () => {
        const endpoint = baseUrl + "usuarios/proveedor/list?proveedor=" + proveedor.id;
        try {
            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText,
            }).then((res) => res.json());
    
            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });                
                throw new Error("Failed to fetch data");
            }
            setUsuarios(data.usuarios);
        } 
        catch (error) {
            console.error("Error", error.message);
        }
    };

    const getPerfiles = async () => {
        const endpoint = baseUrl + "perfiles/list?tipo=PROVEEDOR";
        try {
            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText,
            }).then((res) => res.json());
    
            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
            }
            setPerfiles(data.perfiles);
        } 
        catch (error) {
            console.error("Error", error.message);
        }
    };

    const openNew = () => {
        setProveedor(emptyProveedor);
        setSubmitted(false);
        setProveedorDialog(true);
    };

    const openNewUsuario = () => {
        setUsuario(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProveedorDialog(false);
    };

    const hideUsuarioDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const hideDeleteProveedorDialog = () => {
        setDeleteProveedorDialog(false);
    };

    const hideDeleteUsuarioDialog = () => {
        setDeleteUsuarioDialog(false);
    };

    const hideUsuariosProveedorDialog = () => {
        setSubmitted(false);
        setUsuariosProveedorDialog(false);
    };

    const saveProveedor = async () => {

        var endpoint = baseUrl + "proveedores/save";
        endpoint += "?id=" + proveedor.id;
        endpoint += "&cuenta=" + proveedor.cuenta;
        endpoint += "&rut=" +  proveedor.rut;
        endpoint += "&razonSocial=" + proveedor.razonSocial;
        endpoint += "&correo=" + proveedor.correo;
        endpoint += "&contacto=" + proveedor.contacto;
        endpoint += "&contrasena=" + proveedor.contrasena;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                setSubmitted(true);
                setProveedorDialog(false);
                setProveedor(emptyProveedor);
                getProveedores();
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }

    };

    const saveUsuario = async () => {

        var endpoint = baseUrl + "usuarios/proveedor/save";
        endpoint += "?nombre=" + usuario.nombre;
        endpoint += "&apellido=" +  usuario.apellido;
        endpoint += "&usuario=" + usuario.usuario;
        endpoint += "&contrasena=" + usuario.contrasena;
        endpoint += "&perfil=" + usuario.perfil.id;
        endpoint += "&proveedor=" + proveedor.id;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                setSubmitted(true);
                setUsuarioDialog(false);
                setUsuario(emptyUsuario);
                getUsuarios();
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }

    };

    const editProveedor = (proveedor) => {
        setProveedor({ ...proveedor });
        setProveedorDialog(true);
    };

    const editUsuario = (usuario) => {
        setUsuario({ ...usuario });
        setUsuarioDialog(true);
    };

    const editUsuarios = (proveedor) => {
        setProveedor({ ...proveedor });
        getUsuarios();
        setUsuariosProveedorDialog(true);
    };

    const confirmDeleteProveedor = (proveedor) => {
        setProveedor(proveedor);
        setDeleteProveedorDialog(true);
    };

    const deleteProveedor = async () => {

        var endpoint = baseUrl + "proveedores/delete?id=" + proveedor.id;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                getProveedores();
                setDeleteProveedorDialog(false);
                setProveedor(emptyProveedor);
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Proveedor eliminado', life: 3000 });
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }
        
    };

    const confirmDeleteUsuario = (usuario) => {
        setUsuario(usuario);
        setDeleteUsuarioDialog(true);
    };

    const deleteUsuario = async () => {

        var endpoint = baseUrl + "usuarios/proveedor/delete?id=" + usuario.id;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                getUsuarios();
                setDeleteUsuarioDialog(false);
                setUsuario(emptyUsuario);
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Usuario eliminado', life: 3000 });
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }
        
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proveedor = { ...proveedor };
        _proveedor[`${name}`] = val;
        setProveedor(_proveedor);
    };

    const onInputUsuarioChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;
        setUsuario(_usuario);
    };

    const onPerfilChange = (e) => {
        let _usuario = { ...usuario };
        _usuario['perfil'].id = e.value;
        setUsuario(_usuario);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProveedor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteProveedor(rowData)} />
                <Button icon="pi pi-user" className="p-button-rounded p-button-blue mr-2" onClick={() => editUsuarios(rowData)} />
            </div>
        );
    };

    const actionUsuarioBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteUsuario(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Mantenedor de Proveedores</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const proveedorDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProveedor} />
        </>
    );

    const deleteProveedorDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedorDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteProveedor} />
        </>
    );

    const usuariosProveedorDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideUsuariosProveedorDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProveedor} />
        </>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideUsuarioDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    
    const deleteUsuarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuarioDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteUsuario} />
        </>
    );

    return (

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={proveedores}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron proveedores"
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column body={actionBodyTemplate} headerStyle={{ width: '164px' }}></Column>
                        <Column field="cuenta" header="Cuenta" headerStyle={{ width: '120px', minWidth: '10rem' }}></Column>
                        <Column field="rut" header="RUT" headerStyle={{ width: '120px', minWidth: '10rem' }}></Column>
                        <Column field="razonSocial" header="Razón Social"></Column>

                    </DataTable>

                    <Dialog visible={proveedorDialog} style={{ width: '450px' }} header="Proveedor" modal className="p-fluid" footer={proveedorDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="cuenta">Cuenta</label>
                            <InputText id="cuenta" value={proveedor.cuenta} onChange={(e) => onInputChange(e, 'cuenta')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.cuenta })} />
                            {submitted && !proveedor.cuenta && <small className="p-invalid">La cuenta es obligatoria</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="rut">RUT</label>
                            <InputText id="rut" value={proveedor.rut} onChange={(e) => onInputChange(e, 'rut')} required className={classNames({ 'p-invalid': submitted && !proveedor.rut })} />
                            {submitted && !proveedor.rut && <small className="p-invalid">El RUT es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="razonSocial">Razón Social</label>
                            <InputText id="razonSocial" value={proveedor.razonSocial} onChange={(e) => onInputChange(e, 'razonSocial')} required className={classNames({ 'p-invalid': submitted && !proveedor.razonSocial })} />
                            {submitted && !proveedor.razonSocial && <small className="p-invalid">La razón social es obligatoria</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contacto">Contacto</label>
                            <InputText id="contacto" value={proveedor.contacto} onChange={(e) => onInputChange(e, 'contacto')} className={classNames({ 'p-invalid': submitted && !proveedor.contacto })} />
                        </div>
                        <div className="field">
                            <label htmlFor="correo">Correo</label>
                            <InputText id="correo" value={proveedor.correo} onChange={(e) => onInputChange(e, 'correo')} className={classNames({ 'p-invalid': submitted && !proveedor.contacto })} />
                        </div>
                        <div className="field">
                            <label htmlFor="contrasena">Contraseña</label>
                            <InputText id="contrasena" value={proveedor.contrasena} onChange={(e) => onInputChange(e, 'contrasena')} required className={classNames({ 'p-invalid': submitted && !proveedor.contrasena })} type={showPassword ? "text" : "password"} />
                            {submitted && !proveedor.contrasena && <small className="p-invalid">La contraseña es obligatoria</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProveedorDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProveedorDialogFooter} onHide={hideDeleteProveedorDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {proveedor && (
                                <span>
                                    ¿Realmente desea eliminar el proveedor <b>{proveedor.cuenta}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={usuariosProveedorDialog} style={{ width: '1024px' }} header={"Usuarios " + proveedor.razonSocial} modal className="p-fluid" footer={usuariosProveedorDialogFooter} onHide={hideUsuariosProveedorDialog}>

                        <div className="my-2">
                        <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewUsuario} />
                        </div>

                        <DataTable
                            value={usuarios}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} usuarios"
                            globalFilter={globalFilter}
                            emptyMessage="No se encontraron usuarios"
                            responsiveLayout="scroll"
                        >
                            <Column body={actionUsuarioBodyTemplate} headerStyle={{ width: '112px' }}></Column>
                            <Column field="usuario" header="Usuario"></Column>
                            <Column field="nombre" header="Nombre" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="apellido" header="Apellido" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="perfil.nombre" header="Perfil" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>

                    </Dialog>

                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideUsuarioDialog}>
                        <div className="field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={usuario.nombre} onChange={(e) => onInputUsuarioChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.nombre })} />
                            {submitted && !usuario.nombre && <small className="p-invalid">El nombre es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="apellido">Apellido</label>
                            <InputText id="apellido" value={usuario.apellido} onChange={(e) => onInputUsuarioChange(e, 'apellido')} required className={classNames({ 'p-invalid': submitted && !usuario.apellido })} />
                            {submitted && !usuario.apellido && <small className="p-invalid">El apellido es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="usuario">Usuario</label>
                            <InputText id="usuario" value={usuario.usuario} onChange={(e) => onInputUsuarioChange(e, 'usuario')} required className={classNames({ 'p-invalid': submitted && !usuario.usuario })} />
                            {submitted && !usuario.usuario && <small className="p-invalid">El usuario es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contrasena">Contraseña</label>
                            <InputText id="contrasena" value={usuario.contrasena} onChange={(e) => onInputUsuarioChange(e, 'contrasena')} className={classNames({ 'p-invalid': submitted && !usuario.contrasena })} type={showPassword ? "text" : "password"} />
                            {submitted && !usuario.contrasena && <small className="p-invalid">La contraseña es obligatoria</small>}
                        </div>
                        <div className="field">
                            <label className="mb-3">Perfil</label>
                            <Dropdown value={usuario.perfil.id} onChange={(e) => onPerfilChange(e)} options={perfiles} optionValue="id" optionLabel="nombre" placeholder="Seleccione" />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteUsuarioDialogFooter} onHide={hideDeleteUsuarioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && (
                                <span>
                                    ¿Realmente desea eliminar el usuario <b>{usuario.usuario}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
};

export default Proveedores;
