import React, { useEffect, useState, useRef, useCallback } from "react";
import { Chart } from 'primereact/chart';

import { useGlobalContext } from "../Config";

const Dashboard = (props) => {

  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const bar = useRef(null);
  const [indicadores, setIndicadores] = useState([]);
  const [graficos, setGraficos] = useState([]);

  const getIndicadores = async () => {
    const endpoint = baseUrl + "dashboard/indicadores";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      setIndicadores(data.indicadores);
    } 
    catch (error) {
      console.error("Error", error.message);
    }
  };

  const getGraficos = async() => {
    const endpoint = baseUrl + "dashboard/grafico";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      
      setGraficos({
        labels: [data.grafico.mes1, data.grafico.mes2, data.grafico.mes3, data.grafico.mes4, data.grafico.mes5, data.grafico.mes6, data.grafico.mes7, data.grafico.mes8, data.grafico.mes9, data.grafico.mes10, data.grafico.mes11, data.grafico.mes12],
        datasets: [
          {
            label: "TON",
            data: [data.grafico.val1, data.grafico.val2, data.grafico.val3, data.grafico.val4, data.grafico.val5, data.grafico.val6, data.grafico.val7, data.grafico.val8, data.grafico.val9, data.grafico.val10, data.grafico.val11, data.grafico.val12],
            borderWidth: 2,
            fill: true
          },
        ]
      });
    
    } 
    catch (error) {
      console.error("Error", error.message);
    }
  }

  let options = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        labels: {
        }
      }
    },
    scales: {
      y: {
        ticks: {
        },
        grid: {
        }
      },
      x: {
        categoryPercentage: 0.9,
        barPercentage: 0.8,
        ticks: {
        },
        grid: {
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
  
  useEffect(() => {
    getIndicadores();
    getGraficos();
  }, []);

  const formatNumber = (value) => {
    if (value != undefined) {
      return value.toLocaleString('es-CL');
    }
    return null;
  };

  return (
    
    <div className="col-12 md:col-12">

      {props.perfil == "1" && (
        <>

          <h5>¡Bienvenido!</h5>

          <div className="grid dashboard">
            <div className="col-8 md:col-3">
              <div className="card widget-social">
                <div className="flex justify-content-between align-items-center p-3">
                  <div className="social-icon">
                    <i className="pi pi-check-square blue-color fs-xxlarge"></i>
                  </div>
                  <div className="info flex flex-column">
                    <span className="value">{formatNumber(indicadores?.entrega)}</span>
                    <span className="subtext mt-2">Nº de tickets mes actual</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 md:col-3">
              <div className="card widget-social">
                <div className="flex justify-content-between align-items-center p-3">
                  <div className="social-icon">
                    <i className="pi pi-chart-bar blue-color fs-xxlarge"></i>
                  </div>
                  <div className="info flex flex-column">
                    <span className="value">{formatNumber(indicadores?.volumen)}</span>
                    <span className="subtext mt-2">Ton. entregadas mes actual</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 md:col-3">
              <div className="card widget-social">
                <div className="flex justify-content-between align-items-center p-3">
                  <div className="social-icon">
                    <i className="pi pi-check blue-color fs-xxlarge"></i>
                  </div>
                  <div className="info flex flex-column">
                    <span className="value">{formatNumber(indicadores?.pagada)}</span>
                    <span className="subtext mt-2">Facturas pagadas del mes</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 md:col-3">
              <div className="card widget-social">
                <div className="flex justify-content-between align-items-center p-3">
                  <div className="social-icon">
                    <i className="pi pi-times blue-color fs-xxlarge"></i>
                  </div>
                  <div className="info flex flex-column">
                    <span className="value">{formatNumber(indicadores?.vencer)}</span>
                    <span className="subtext mt-2">Facturas por pagar del mes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5>Resumen</h5>

          <div className="grid">
            <div className="col-12 lg:col-2">
              <div className="card shadow-2 p-3 border-round h-mid">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Tickets</span>
                    <h1>{formatNumber(indicadores?.entregaAcumulado)}</h1>
                  </div>
                  <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-check-square text-blue-500 text-xl"></i>
                  </div>
                </div>
                <span className="text-500">Cantidad historica de tickets</span>
              </div>
            </div>
            <div className="col-12 lg:col-2">
              <div className="card shadow-2 p-3 border-round h-mid">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Toneladas</span>
                    <h1>{formatNumber(indicadores?.volumenAcumulado)}</h1>
                  </div>
                  <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-chart-bar text-blue-500 text-xl"></i>
                  </div>
                </div>
                <span className="text-500">Volumen histórico entregado Ton.</span>
              </div>
            </div>
            <div className="col-12 lg:col-8">
              <div className="card shadow-2 p-3 border-round">
                <div className="card-header">
                  <h5>Toneladas entregadas los últimos meses</h5>
                </div>
                <Chart type="bar" data={graficos} options={options} />
              </div>
            </div>
          </div>

        </>

      )}

    </div>

  );
};

export default Dashboard;
