import React, { useEffect, useState, useCallback } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useGlobalContext } from "../Config";

const Documentos = (props) => {

  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();

  const [empresa, setEmpresa] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const [folio, setFolio] = useState([]);
  const [estado, setEstado] = useState([]);
  const [rut, setRut] = useState([]);
  const [razonSocial, setRazonSocial] = useState([]);
  const [codigo, setCodigo] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const setLoading = (value) => {
    props.setLoading(value);
  };

  const estados = [
    {label: '', value: ''},
    {label: 'Pagado', value: 'PAGADO'},
    {label: 'Pendiente', value: 'PENDIENTE'}
  ];
 
  const getEmpresas = async () => {
    setLoading(true);
    const endpoint = baseUrl + "empresas/list";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      setEmpresas(data.empresas);
    } 
    catch (error) {
      console.error("Error", error.message);
    }
    setLoading(false);
  };

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedEmpresas, setSelectedEmpresas] = useState('');

  useEffect(() => {
    getEmpresas();
  }, []);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    switch (name) {
      case "folio":
        setFolio(val);
        break;
      case "estado":
        setEstado(val);
        break;
      case "rut":
        setRut(val);
        break;
      case "razonSocial":
        setRazonSocial(val);
        break;
      case "codigo":
        setCodigo(val);
        break;
      case "empresa":
        setEmpresa(val);
        break;
      }
  };

  const handleSubmit = useCallback((e) => {

    e.preventDefault();

    var endpoint = baseUrl + "reportes/documentos" 
    endpoint += "?desde=" + desde.getFullYear() + "-" + (desde.getMonth() + 1) + "-" + desde.getDate();
    endpoint += "&hasta=" + hasta.getFullYear() + "-" + (hasta.getMonth() + 1) + "-" + hasta.getDate();
    endpoint += "&folio=" + folio;
    endpoint += "&estado=" + estado;
    endpoint += "&rut=" + rut;
    endpoint += "&razonSocial=" + razonSocial;
    endpoint += "&codigo=" + codigo;
    endpoint += "&empresa=" + empresa;

    setLoading(true);
    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      setDocumentos(data.documentos);
      setLoading(false);
    })();    

  });

  const formatCurrency = (value) => {
    if (value != undefined) {
      return value.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
    }
  };

  const importeBodyTemplate = (rowData) => {
    return (
      <div style={{textAlign: "right"}}>
        {formatCurrency(rowData.importe)}
      </div>
    );
  }

  return (

    <div className="col-12 md:col-12">
      <h5>Consulta de Documentos</h5>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-1">
              <label htmlFor={desde}>Desde</label> 
              <Calendar value={desde} onChange={(e) => setDesde(e.value)} maxDate={new Date()}/>
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor={hasta}>Hasta</label> 
              <Calendar value={hasta} onChange={(e) => setHasta(e.value)} maxDate={new Date()}/>
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor={folio}>Folio</label> 
              <InputText value={folio} onChange={(e) => onInputChange(e, 'folio')} />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor={estado}>Estado</label> 
              <Dropdown value={estado} onChange={(e) => onInputChange(e, 'estado')} options={estados} optionValue="value" optionLabel="label" placeholder="Seleccione" className="w-full md:w-14rem" />
            </div>

            <div className="field col-12 md:col-1" style={{display: props.proveedor == 0 ? 'block' : 'none'}}>
              <label htmlFor={rut}>Rut Proveedor</label> 
              <InputText value={rut} onChange={(e) => onInputChange(e, 'rut')} />
            </div>

            <div className="field col-12 md:col-1" style={{display: props.proveedor == 0 ? 'block' : 'none'}}>
              <label htmlFor={razonSocial}>Razón Social</label> 
              <InputText value={razonSocial} onChange={(e) => onInputChange(e, 'razonSocial')} />
            </div>

            <div className="field col-12 md:col-1" style={{display: props.proveedor == 0 ? 'block' : 'none'}}>
              <label htmlFor={codigo}>Código SAP</label> 
              <InputText value={codigo} onChange={(e) => onInputChange(e, 'codigo')} />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor={empresa}>Empresa</label> 
              <Dropdown value={empresa} onChange={(e) => onInputChange(e, 'empresa')} options={empresas} optionLabel="descripcion" optionValue="cuenta" className="w-full md:w-14rem" />
            </div>
            <div className="field col-12 md:col-1">
              <Button
                type="submit"
                name="filterButton"
                className="login-button mb-3 px-3"
                label="Aplicar"
                style={{ backgroundColor: "#007ad9" }}
              />
            </div>

          </div>
        </form>
        <DataTable value={documentos} tableStyle={{ minWidth: '50rem' }} showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No se encontraron documentos">
          <Column field="numero" header="Folio"></Column>
          <Column field="texto" header="Tipo"></Column>
          <Column field="rut" header="Rut"></Column>
          <Column field="razonSocial" header="Razón Social"></Column>
          <Column field="fechaCompensacion" header="Estado"></Column>
          <Column field="fechaDocumento" header="Fecha Documento"></Column>
          <Column field="fechaVencimiento" header="Fecha Vencimiento"></Column>
          <Column field="fechaCompensacion" header="Fecha Pago"></Column>
          <Column body={importeBodyTemplate} header="Monto"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Documentos;
