import React, { useEffect, useRef, useState } from "react";

import { classNames } from "primereact/utils";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import AppTopbar from "./AppTopbar";
import AppBreadcrumb from "./AppBreadcrumb";
import AppInlineMenu from "./AppInlineMenu";

import AppMenu from "./AppMenu";
import AppConfig from "./AppConfig";
import AppRightMenu from "./AppRightMenu";

import DashboardAnalytics from "./components/DashboardAnalytics";
import ButtonDemo from "./components/ButtonDemo";
import ChartDemo from "./components/ChartDemo";
import MessagesDemo from "./components/MessagesDemo";
import Documentation from "./components/Documentation";
import FileDemo from "./components/FileDemo";
import FormLayoutDemo from "./components/FormLayoutDemo";
import InputDemo from "./components/InputDemo";
import ListDemo from "./components/ListDemo";
import MiscDemo from "./components/MiscDemo";
import MenuDemo from "./components/MenuDemo";
import OverlayDemo from "./components/OverlayDemo";
import PanelDemo from "./components/PanelDemo";
import TableDemo from "./components/TableDemo";
import TreeDemo from "./components/TreeDemo";
import FloatLabelDemo from "./components/FloatLabelDemo";
import InvalidStateDemo from "./components/InvalidStateDemo";
import Solicitudes from "./components/Solicitudes";
import Solicitud from "./components/Solicitud";
import Productos from "./components/Productos";
import PDF from "./pages/PDF";
/* import Proveedores from "./components/Proveedores"; */
import Solicitantes from "./pages/Solicitantes";
import Aprobadores from "./pages/Aprobadores";

import BlocksDemo from "./components/BlocksDemo";
import IconsDemo from "./utilities/IconsDemo";

import Crud from "./pages/Crud";
import Calendar from "./pages/Calendar";
import EmptyPage from "./pages/EmptyPage";
import Invoice from "./pages/Invoice";
import Help from "./pages/Help";
import TimelineDemo from "./pages/TimelineDemo";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import Login from "./pages/Login";
import AppContext from "./Config";
import { useGlobalContext } from "./Config";
import Proveedor from "./components/Proveedor";
import Recuperar from "./pages/Recuperar";
import CrearNuevaContrasena from "./pages/CrearNuevaContrasena";
import ConsultaCertificados from "./pages/ConsultaCertificados";
import SolicitudCertificados from "./pages/SolicitudCertificados";
import Dashboard from "./pages/Dashboard";
import Resumida from "./pages/Resumida";
import Detalle from "./pages/Detalle";
import Documentos from "./pages/Documentos";
import Proveedores from "./pages/Proveedores";
import Usuarios from "./pages/Usuarios";
import { constrainPoint } from "@fullcalendar/core/internal";

export const RTLContext = React.createContext();

const App = () => {
  const [menuMode, setMenuMode] = useState("static");
  const [inlineMenuPosition, setInlineMenuPosition] = useState("bottom");
  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [colorMode, setColorMode] = useState("light");
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState("filled");
  const [isRTL, setRTL] = useState(false);
  const [ripple, setRipple] = useState(true);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [menuTheme, setMenuTheme] = useState("light");
  const [topbarTheme, setTopbarTheme] = useState("blue");
  const [theme, setTheme] = useState("indigo");
  const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
  const [inlineMenuActive, setInlineMenuActive] = useState({});
  const [newThemeLoaded, setNewThemeLoaded] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const {isRecuperar} = useGlobalContext();
  const {setIsRecuperar} = useGlobalContext();
  const {setIsLoggedIn} = useGlobalContext();
  const {baseUrl} = useGlobalContext();
  const copyTooltipRef = useRef();
  let currentInlineMenuKey = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [contrasenaRestablecida, setContrasenaRestablecida] = useState(false);
  const [debeCrearContrasena, setDebeCrearContrasena] = useState(false);
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [loading, setLoading] = useState(false);

  /*
  const [clientId, setClientId] = useState(0);
  const [privilegios, setPrivilegios] = useState();
  const {isLoggedIn} = useGlobalContext();
  const {setUsuario_id} = useGlobalContext();
  const [empresaGlobal, setEmpresaGlobal] = useState(0);
  */
    
  PrimeReact.ripple = true;

  let searchClick;
  let topbarItemClick;
  let menuClick;
  let inlineMenuClick;

  var customMenu = [];
  customMenu[0] = {
    label: "Menu", 
    icon: "pi pi-fw pi-list", 
    items: []
  };

  if (sessionStorage.getItem("chatarra") === "true") {
    customMenu[0]["items"].push({
      label: "Tickets", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Resumida",
          icon: "pi pi-fw pi-list", 
          to: "/resumida"
        }, 
        {
          label: "Detalle", 
          icon: "pi pi-fw pi-list", 
          to: "/detalle"
        }
      ]      
    });
  }

  if (sessionStorage.getItem("general") === "true") {
    customMenu[0]["items"].push({
      label: "Documentos", 
      icon: "pi pi-fw pi-list", 
      to: "/documentos" 
    });
  }

  if (sessionStorage.getItem("perfil") == "1") {

    customMenu[0]["items"].push({
      label: "Certificados DF", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Solicitud ",
          icon: "pi pi-fw pi-list", 
          to: "/solicitud-certificados"
        }, 
        {
          label: "Consulta ",
          icon: "pi pi-fw pi-list", 
          to: "/consulta-certificados"
        }, 
      ]      
    });

    customMenu[0]["items"].push({ 
      label: 
      "Administración", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Proveedores", 
          icon: "pi pi-fw pi-list", 
          to: "/proveedores"
        }, 
        {
          label: "Usuarios", 
          icon: "pi pi-fw pi-list", 
          to: "/usuarios"
        }
      ]
    });

  }

  if (sessionStorage.getItem("perfil") == "2") {

    customMenu[0]["items"].push({
      label: "Certificados DF", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Solicitud ",
          icon: "pi pi-fw pi-list", 
          to: "/solicitud-certificados"
        }, 
        {
          label: "Consulta ",
          icon: "pi pi-fw pi-list", 
          to: "/consulta-certificados"
        }, 
      ]      
    });

    customMenu[0]["items"].push({ 
      label: 
      "Administración", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Proveedores", 
          icon: "pi pi-fw pi-list", 
          to: "/proveedores"
        }, 
        {
          label: "Usuarios", 
          icon: "pi pi-fw pi-list", 
          to: "/usuarios"
        }
      ]
    });
    
  }

  if (sessionStorage.getItem("perfil") == "3") {
    customMenu[0]["items"].push({ 
      label: 
      "Administración", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Usuarios", 
          icon: "pi pi-fw pi-list", 
          to: "/usuarios"
        }
      ]
    });
  }

  if (sessionStorage.getItem("perfil") == "4") {
  }

  if (sessionStorage.getItem("perfil") == "5") {
    customMenu[0]["items"].push({ 
      label: 
      "Administración", 
      icon: "pi pi-fw pi-list", 
      items: [
        {
          label: "Usuarios", 
          icon: "pi pi-fw pi-list", 
          to: "/usuarios"
        }
      ]
    });
  }

  if (sessionStorage.getItem("perfil") == "6") {
  }

  const menu = customMenu;

  /*
  const menu = [
    {
      label: "Menu",
      icon: "pi pi-fw pi-list",
      items: [
        { 
          label: "Tickets", 
          icon: "pi pi-fw pi-list", 
          items: [
            {
              label: "Resumida",
              icon: "pi pi-fw pi-list", 
              to: "/resumida"
            }, 
            {
              label: "Detalle", 
              icon: "pi pi-fw pi-list", 
              to: "/detalle"
            }
          ]
        },
        { 
          label: "Documentos", 
          icon: "pi pi-fw pi-list", 
          to: "/documentos" 
        },
        { 
          label: 
          "Administración", 
          icon: "pi pi-fw pi-list", 
          items: [
            {
              label: "Proveedores", 
              icon: "pi pi-fw pi-list", 
              to: "/proveedores"
            }, 
            {
              label: "Usuarios", 
              icon: "pi pi-fw pi-list", 
              to: "/usuarios"
            }
          ]
        },
      ],
    },
  ];
  */

  const userPrivilegios =
    JSON.parse(sessionStorage.getItem("privilegios")) || [];

  if (userPrivilegios.includes(22)) {
    const mantenedoresSubmenu = {
      label: "Mantenedores",
      icon: "pi pi-fw pi-list",
      items: [
        {
          label: "Solicitantes",
          icon: "pi pi-fw pi-user",
          to: "/solicitantes",
        },
        {
          label: "Aprobadores",
          icon: "pi pi-fw pi-id-card",
          to: "/aprobadores",
        },
      ],
    };
    menu[0].items.push(mantenedoresSubmenu);
  }

  const routes = [
    { path: "/", parent: "", label: "" },
    {
      path: "/documentation",
      parent: "Favorites",
      label: "Dashboard Analytics",
    },
    {
      path: "/favorites/dashboardanalytics",
      parent: "UI Kit",
      label: "Form Layout",
    },
    { path: "/uikit/formlayout", parent: "UI Kit", label: "Input" },
    { path: "/", parent: "UI Kit", label: "Float Label" },
    { path: "/", parent: "UI Kit", label: "Invalid State" },
    { path: "/", parent: "UI Kit", label: "Button" },
    { path: "/", parent: "UI Kit", label: "Table" },
    { path: "/", parent: "UI Kit", label: "List" },
    { path: "/", parent: "UI Kit", label: "Panel" },
    { path: "/", parent: "UI Kit", label: "Tree" },
    { path: "/", parent: "UI Kit", label: "Overlay" },
    { path: "/", parent: "UI Kit", label: "Menu" },
    { path: "/", parent: "UI Kit", label: "Message" },
    { path: "/", parent: "UI Kit", label: "File" },
    { path: "/", parent: "UI Kit", label: "Chart" },
    { path: "/", parent: "UI Kit", label: "Misc" },
    { path: "/", parent: "Utilities", label: "Icons" },
    { path: "/", parent: "PrimeBlocks", label: "Blocks" },
    { path: "/", parent: "Pages", label: "Crud" },
    { path: "/", parent: "Pages", label: "Calendar" },
    { path: "/", parent: "Pages", label: "Timeline" },
    { path: "/", parent: "Pages", label: "Invoice" },
    { path: "/", parent: "Pages", label: "Login" },
    { path: "/", parent: "Pages", label: "Help" },
    { path: "/", parent: "Pages", label: "Empty" },
    { path: "/", parent: "Pages", label: "Access" },
    { path: "/", parent: "Start", label: "Documentation" },
    /* { path: '/', parent: '', label: 'Oportunidades' }, */
    { path: "/", parent: "", label: "Solicitudes" },
    { path: "/", parent: "", label: "Solicitud" },
    { path: "/", parent: "", label: "Proveedor" },
    { path: "/", parent: "", label: "Proveedores" },
    { path: "/", parent: "", label: "Productos" },
    { path: "/", parent: "", label: "Solicitantes" },
    /* { path: '/', parent: '', label: 'Rubros' },
        { path: '/', parent: '', label: 'Palabras Clave' }, */
    { path: "/", parent: "", label: "Login" },
  ];

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    if (menuMode === "overlay") {
      hideOverlayMenu();
    }
    if (menuMode === "static") {
      setDesktopMenuActive(true);
    }
  }, [menuMode]);

  useEffect(() => {
    onColorModeChange(colorMode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuThemeChange = (theme) => {
    setMenuTheme(theme);
  };

  const onTopbarThemeChange = (theme) => {
    setTopbarTheme(theme);
  };

  const onThemeChange = (theme) => {
    setTheme(theme);
    const themeLink = document.getElementById("theme-css");
    const themeHref = "assets/theme/" + theme + "/theme-" + colorMode + ".css";
    replaceLink(themeLink, themeHref);
  };

  const onColorModeChange = (mode) => {
    setColorMode(mode);
    setIsInputBackgroundChanged(true);

    if (isInputBackgroundChanged) {
      if (mode === "dark") {
        setInputStyle("filled");
      } else {
        setInputStyle("outlined");
      }
    }

    if (mode === "dark") {
      setMenuTheme("dark");
      setTopbarTheme("dark");
    } else {
      setMenuTheme("light");
      setTopbarTheme("blue");
    }

    const layoutLink = document.getElementById("layout-css");
    const layoutHref = "assets/layout/css/layout-" + mode + ".css";
    replaceLink(layoutLink, layoutHref);

    const themeLink = document.getElementById("theme-css");
    const urlTokens = themeLink.getAttribute("href").split("/");
    urlTokens[urlTokens.length - 1] = "theme-" + mode + ".css";
    const newURL = urlTokens.join("/");

    replaceLink(themeLink, newURL, () => {
      setNewThemeLoaded(true);
    });
  };

  const replaceLink = (linkElement, href, callback) => {
    if (isIE()) {
      linkElement.setAttribute("href", href);

      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        const _linkElement = document.getElementById(id);
        _linkElement && _linkElement.remove();
        cloneLinkElement.setAttribute("id", id);

        if (callback) {
          callback();
        }
      });
    }
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onInlineMenuPositionChange = (mode) => {
    setInlineMenuPosition(mode);
  };

  const onMenuModeChange = (mode) => {
    setMenuMode(mode);
  };

  const onRTLChange = () => {
    setRTL((prevState) => !prevState);
  };

  const onMenuClick = (event) => {
    menuClick = true;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;
    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onSearch = (event) => {
    searchClick = true;
    setSearchActive(event);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
      hideOverlayMenu();
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event) => {
    setMenuActive((prevState) => !prevState);
  };

  const onRightMenuButtonClick = () => {
    setRightMenuActive((prevState) => !prevState);
  };

  const onMobileTopbarButtonClick = (event) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onDocumentClick = (event) => {
    if (!searchClick && event.target.localName !== "input") {
      setSearchActive(false);
    }

    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!menuClick && (menuMode === "overlay" || !isDesktop())) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
      let menuKeys = { ...inlineMenuActive };
      menuKeys[currentInlineMenuKey.current] = false;
      setInlineMenuActive(menuKeys);
    }

    if (!menuClick && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }

    searchClick = false;
    topbarItemClick = false;
    inlineMenuClick = false;
    menuClick = false;
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const onInlineMenuClick = (e, key) => {
    let menuKeys = { ...inlineMenuActive };
    if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
      menuKeys[currentInlineMenuKey.current] = false;
    }

    menuKeys[key] = !menuKeys[key];
    setInlineMenuActive(menuKeys);
    currentInlineMenuKey.current = key;
    inlineMenuClick = true;
  };

  const layoutContainerClassName = classNames(
    "layout-wrapper ",
    "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme,
    {
      "layout-menu-static": menuMode === "static",
      "layout-menu-overlay": menuMode === "overlay",
      "layout-menu-slim": menuMode === "slim",
      "layout-menu-horizontal": menuMode === "horizontal",
      "layout-menu-active": desktopMenuActive,
      "layout-menu-mobile-active": mobileMenuActive,
      "layout-topbar-mobile-active": mobileTopbarActive,
      "layout-rightmenu-active": rightMenuActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
      "layout-rtl": isRTL,
    }
  );

  const handleLogin = (email, password) => {
    
    const checkLogin = async () => {
      if (!email || !password) {
        alert("Complete todos los campos");
        return;
      }

      try {
        // Fetch para login
        const myHeaders = new Headers();
        const myHeaders2 = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");
        // myHeaders2.append(
        //   "Authorization",
        //   `Basic ${btoa(email + ":" + password)}`
        // );

        const urlencoded = new URLSearchParams();
        urlencoded.append("username", email);
        urlencoded.append("password", password);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          credentials: "include",
          redirect: "follow",
        };

        const loginResponse = await fetch(`${baseUrl}login`, requestOptions);

        if (loginResponse.status !== 200) {
          alert("Usuario No Autorizado");
          return;
        }

        if (loginResponse.status === 200) {
          // Fetch para obtener datos del usuario
          const userResponse = await fetch(`${baseUrl}user`, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
          });
          console.log("userResponse", userResponse);
          if (!userResponse.ok) {
            throw new Error("Error al obtener datos");
          }

          const userData = await userResponse.json();
          if (userResponse.status === 200) {
            // Verificar si el usuario necesita cambiar la contraseña

            if (userData.cambiar) {
              setCorreoUsuario(userData.usuario);
              setDebeCrearContrasena(true);
              return;
            } 
            else {
              sessionStorage.setItem("isLoggedIn", "true");
              sessionStorage.setItem("perfil", userData.idPerfil);
              sessionStorage.setItem(
                "empresas",
                JSON.stringify(userData.empresas)
              );
              sessionStorage.setItem(
                "empresasProveedor", 
                JSON.stringify(userData.empresasProveedor)
              );
              sessionStorage.setItem(
                "privilegios",
                JSON.stringify(userData.privilegios)
              );
              sessionStorage.setItem("proveedor", userData.proveedor);
              sessionStorage.setItem("chatarra", userData.chatarra);
              sessionStorage.setItem("general", userData.general);
              sessionStorage.setItem("clientId", userData.usuario);
              sessionStorage.setItem("idUsuario", userData.id);

              /*
              sessionStorage.setItem("id", userData.id);
              sessionStorage.setItem(
                "selectedEmpresa",
                JSON.stringify(userData.empresas[0])
              );
              */

              // Configurar estados para el componente
              setIsLoggedIn(true);
              /*
              setCorreoUsuario(userData.usuario);
              setClientId(userData.nombreCliente);
              setUsuario_id(userData.id);
              setPrivilegios(userData.privilegios);
              */
              navigate("/");

            }
          }
        }
      } catch (error) {
        console.error("Error durante la autenticación:", error);
        alert("No pudo establecerse conexión con el servidor");
      }
    };

    checkLogin();
  };

  const handleRecuperarContraseña = (emailRecuperar) => {
    setLoading(true);
    const checkRecuperarContraseña = async () => {
      try {
        const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailFormat.test(emailRecuperar)) {
          alert("Ingrese un formato de correo válido");
          setLoading(false);

          return;
        }

        const response = await fetch(
          baseUrl + "recuperarContrasena?usuario=" + emailRecuperar
        );
        if (response.status === 401) {
          alert("Usuario o clave inválida");
          setLoading(false);

          return;
        }
        if (response.status === 500) {
          alert("Error interno del servidor");
          setLoading(false);

          return;
        }
        if (response.status === 200) {
          const data = await response.json();
          if (data.respuesta) {
            setLoading(false);

            setContrasenaRestablecida(true);
          }
        }
      } catch (error) {
        setLoading(false);

        console.error(error);
      }
    };
    checkRecuperarContraseña();
  };

  const handleCrearNuevaContrasena = (oldPass, newPass) => {
    const checkCrearContrasena = async () => {
      try {
        setLoading(true);

        const response = await fetch(
          baseUrl +
            `cambiarContrasena?usuario=${correoUsuario}&pass=${oldPass}&nueva=${newPass} `
        );

        if (response.status === 200) {
          const data = await response.json();
          if (data.respuesta) {
            alert("Por favor ingrese con la contraseña que acaba de crear");
            setDebeCrearContrasena(false);
            navigate("/");
          } else {
            alert("Hubo un error al crear la contraseña");
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkCrearContrasena();
    setLoading(false);
  };

  const handleVolverInicio = () => {
    setIsRecuperar(false);
  };

  const handleButtonClickRecuperar = () => {
    setIsRecuperar(true);
    setContrasenaRestablecida(false);
  };

  const isLoggedInStorage = sessionStorage.getItem("isLoggedIn") === "true";

  if (location.pathname.startsWith("certificado")) {
    return (
      <PDF
        colorMode="light"
        isNewThemeLoaded={false}
        onNewThemeChange={() => {}}
        location={location}
      />
    );
  }

  if (!isLoggedInStorage && location.pathname !== "/proveedor" && !location.pathname.startsWith("/certificado") && !isRecuperar && !debeCrearContrasena) {
    return (
      <Login
        colorMode="light"
        isNewThemeLoaded={false}
        onNewThemeChange={() => {}}
        location={location}
        handleLogin={handleLogin}
        handleButtonClickRecuperar={handleButtonClickRecuperar}
      />
    );
  }

  if (!isLoggedInStorage && location.pathname !== "/proveedor" && !location.pathname.startsWith("/certificado") && isRecuperar) {
    return (
      <Recuperar
        colorMode="light"
        isNewThemeLoaded={false}
        onNewThemeChange={() => {}}
        location={location}
        handleRecuperarContraseña={handleRecuperarContraseña}
        handleVolverInicio={handleVolverInicio}
        contrasenaRestablecida={contrasenaRestablecida}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  if (location.pathname !== "/proveedor" && !location.pathname.startsWith("/certificado") && debeCrearContrasena) {
    return (
      <CrearNuevaContrasena
        colorMode="light"
        isNewThemeLoaded={false}
        onNewThemeChange={() => {}}
        location={location}
        setDebeCrearContrasena={setDebeCrearContrasena}
        handleCrearNuevaContrasena={handleCrearNuevaContrasena}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  return (
    <RTLContext.Provider value={isRTL}>
      <AppContext>
        {" "}
        <div className={layoutContainerClassName} onClick={onDocumentClick}>
          <Tooltip
            ref={copyTooltipRef}
            target=".block-action-copy"
            position="bottom"
            content="Copied to clipboard"
            event="focus"
          />

          <AppTopbar
            horizontal={isHorizontal()}
            activeTopbarItem={activeTopbarItem}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
            onRightMenuButtonClick={onRightMenuButtonClick}
            onMobileTopbarButtonClick={onMobileTopbarButtonClick}
            mobileTopbarActive={mobileTopbarActive}
            searchActive={searchActive}
            onSearch={onSearch}
            clientId={sessionStorage.getItem("clientId")}
          />

          {(() => {
            if (isLoggedInStorage) {
              return (
                <>
                  <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                      {(inlineMenuPosition === "top" ||
                        inlineMenuPosition === "both") && (
                        <AppInlineMenu
                          menuKey="top"
                          inlineMenuActive={inlineMenuActive}
                          onInlineMenuClick={onInlineMenuClick}
                          horizontal={isHorizontal()}
                          menuMode={menuMode}
                        />
                      )}
                      <AppMenu
                        model={menu}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        menuMode={menuMode}
                        active={menuActive}
                      />
                    </div>
                  </div>
                </>
              );
            }
          })()}

          <div className="layout-main">
            {/* <AppBreadcrumb routes={routes} display="none" /> */}

            <div className="layout-content">
              <Routes>
                <Route path="/documentation" element={<Documentation />} />
                
                <Route path="/" element={<Dashboard perfil={sessionStorage.getItem("perfil")}/>}/>

                <Route
                  path="/resumida"
                  element={
                    <Resumida
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                      proveedor={sessionStorage.getItem("proveedor")}
                      perfil={sessionStorage.getItem("perfil")}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="/detalle"
                  element={
                    <Detalle
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                      proveedor={sessionStorage.getItem("proveedor")}
                      perfil={sessionStorage.getItem("perfil")}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="/documentos"
                  element={
                    <Documentos
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                      proveedor={sessionStorage.getItem("proveedor")}
                      perfil={sessionStorage.getItem("perfil")}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="/proveedores"
                  element={
                    <Proveedores
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                      proveedor={sessionStorage.getItem("proveedor")}
                      perfil={sessionStorage.getItem("perfil")}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="/usuarios"
                  element={
                    <Usuarios
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                      proveedor={sessionStorage.getItem("proveedor")}
                      perfil={sessionStorage.getItem("perfil")}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="/favorites/dashboardanalytics"
                  element={
                    <DashboardAnalytics
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                    />
                  }
                />
                <Route path="/uikit/formlayout" element={<FormLayoutDemo />} />
                <Route path="/uikit/floatlabel" element={<FloatLabelDemo />} />
                <Route path="/uikit/input" element={<InputDemo />} />
                <Route
                  path="/uikit/invalidstate"
                  element={<InvalidStateDemo />}
                />
                <Route path="/uikit/button" element={<ButtonDemo />} />
                <Route path="/uikit/table" element={<TableDemo />} />
                <Route path="/uikit/list" element={<ListDemo />} />
                <Route path="/uikit/tree" element={<TreeDemo />} />
                <Route path="/uikit/panel" element={<PanelDemo />} />
                <Route path="/uikit/overlay" element={<OverlayDemo />} />
                <Route path="/uikit/menu/*" element={<MenuDemo />} />
                <Route path="/uikit/message" element={<MessagesDemo />} />
                <Route path="/uikit/file" element={<FileDemo />} />
                <Route
                  path="/uikit/chart"
                  element={
                    <ChartDemo
                      colorMode={colorMode}
                      isNewThemeLoaded={newThemeLoaded}
                      onNewThemeChange={(e) => setNewThemeLoaded(e)}
                      location={location}
                    />
                  }
                />
                <Route path="/uikit/misc" element={<MiscDemo />} />
                <Route path="/primeblocks/blocks" element={<BlocksDemo />} />
                <Route path="/utilities/icons" element={<IconsDemo />} />
                <Route path="/pages/crud" element={<Crud />} />
                <Route path="/pages/calendar" element={<Calendar />} />
                <Route path="/pages/help" element={<Help />} />
                <Route path="/pages/invoice" element={<Invoice />} />
                <Route path="/pages/empty" element={<EmptyPage />} />
                <Route path="/pages/timeline" element={<TimelineDemo />} />
                <Route
                  path="/solicitud-certificados"
                  element={
                    <SolicitudCertificados
                    />
                  }
                />
                <Route
                  path="/consulta-certificados"
                  element={
                    <ConsultaCertificados
                    />
                  }
                />
                <Route path="/certificado/:codigo" element={<PDF />} /> 
              </Routes>
            </div>
          </div>

          <AppConfig
            inputStyle={inputStyle}
            onInputStyleChange={onInputStyleChange}
            rippleEffect={ripple}
            onRippleEffect={onRipple}
            menuMode={menuMode}
            onMenuModeChange={onMenuModeChange}
            inlineMenuPosition={inlineMenuPosition}
            onInlineMenuPositionChange={onInlineMenuPositionChange}
            colorMode={colorMode}
            onColorModeChange={onColorModeChange}
            menuTheme={menuTheme}
            onMenuThemeChange={onMenuThemeChange}
            topbarTheme={topbarTheme}
            onTopbarThemeChange={onTopbarThemeChange}
            theme={theme}
            onThemeChange={onThemeChange}
            isRTL={isRTL}
            onRTLChange={onRTLChange}
          />

          <AppRightMenu
            rightMenuActive={rightMenuActive}
            onRightMenuButtonClick={onRightMenuButtonClick}
            display="none"
          />

          {mobileMenuActive && <div className="layout-mask modal-in"></div>}
          
         
        </div>
      </AppContext>
    </RTLContext.Provider>
  );
};

export default App;
