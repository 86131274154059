import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode";
import { useGlobalContext } from "../Config";
import moment from "moment";
import "moment/locale/es"; 
import { Button } from "primereact/button";

const PDF = () => {

    const { baseUrl } = useGlobalContext();
    const { headerText } = useGlobalContext();
    const [estado, setEstado] = useState("");
    const [fechaSolicitud, setFechaSolicitud] = useState("");
    const [tableData, setTableData] = useState([]);
    
    const cardStyles = {
        imagen: {
            margin: "auto",
            width: "40%",
        },
    };

    const formatDateToDDMMYYYY = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };
        
    let { codigo  } = useParams();

    const getData = async() => {

        let desofuscado = parseInt(codigo, 32) - 10000000;

        const endpoint = baseUrl + "getDataPDFByIdCertificado?idCertificado=" + desofuscado;
        const data = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: headerText,
        }).then((res) => res.json());

        if (data.mensaje !== "OK") {
            throw new Error("Failed to fetch data");
        }

        setEstado(data.estado);
        setFechaSolicitud(data.fechaSolicitud);

        let aux = [];

        if (data.certificados.length > 0) {

            const getValueOrDefault = (value, defaultValue = "No info") => {
                return (typeof value === "string" || typeof value === "number") && value !== null ? value : defaultValue;
            };

            aux = data.certificados.map((item) => [
                getValueOrDefault(item.oc),
                getValueOrDefault(item.numero_ticket),
                getValueOrDefault(item.nombre_transportista),
                getValueOrDefault(item.rut_transportista, "Falta RUT"),
                getValueOrDefault(item.patente),
                getValueOrDefault(item.guia),
                getValueOrDefault(item.peso),
                getValueOrDefault(item.unidad),
                item.fecha ? formatDateToDDMMYYYY(item.fecha) : "No info",
                getValueOrDefault(item.planta),
                getValueOrDefault(item.proveedor_razon_social),
                getValueOrDefault(item.proveedor_rut),
                getValueOrDefault(item.proveedor_codigo_sap),
                getValueOrDefault(item.rut_destinatario),
                getValueOrDefault(item.razon_social_destinatario),
            ]);

            setTableData(aux);

        }

    }

    const generateImprovedCertificadoPDF = async (idCertificado) => {

        const pdf = new jsPDF("p", "mm", "a4");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const marginLeft = 30;
        const marginTop = 15;
        const footerHeight = 70; // Fixed footer height
        const contentHeight = pageHeight - marginTop - footerHeight; // Height available for content
        let currentY = marginTop; // Start content position

        let background = await loadBackgroundImage();

        async function loadBackgroundImage() {

            const logoUrlRemote = "https://proveedores.aza.cl/react/logoFondoAza.png"; // Remote logo URL
            const logoUrlLocal = "/logoFondoAza.png"; // Local logo URL

            async function fetchImage(url) {
                const response = await fetch(url);
                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            }

            try {
                return await fetchImage(logoUrlRemote);
            } 
            catch (error) {
                console.error("Failed to load remote logo, falling back to local logo:", error);
                try {
                    return await fetchImage(logoUrlLocal);
                } 
                catch (localError) {
                    console.error("Failed to load local logo as well:", localError);
                    throw new Error("Failed to load any logo image."); // Re-throw or handle accordingly
                }
            }
        }

        // Function to add background image to a page
        function addBackgroundImage(startY, height) {
        if (estado === "APROBADO") {
            const imageWidth = pageWidth - marginLeft - 30;
            pdf.addImage(background, "PNG", marginLeft, startY, imageWidth, height);
        } 
        else {

            const watermarkText =
            estado === "SOLICITADO" ? "SOLICITADO" : "RECHAZADO";

            const textX = pageWidth / 2;
            const textY = pageHeight / 2;

            pdf.saveGraphicsState();

            if (watermarkText === "SOLICITADO") {
            pdf.setTextColor(169, 169, 169); // Gray
            } 
            else {
            pdf.setTextColor(255, 0, 0);
            }
            pdf.setFontSize(60);
            pdf.setFont("helvetica", "bold");

            // Add estado watermark diagonally
            pdf.text(watermarkText, textX + 20, textY + 10, {
            angle: 45, // Rotate diagonally
            align: "center",
            });

            pdf.restoreGraphicsState();
        }
        }

        const addPageFooter = async (pdf) => {

        const qrCodeData = "https://proveedores.aza.cl/certificado/" + codigo;
        const qrCodeImage = await QRCode.toDataURL(qrCodeData, { width: 100 });
        const qrCodeSize = 30; // Size of the QR code
        const qrCodeMargin = 10; // Margin around the QR code

        // Define the position for the footer
        const qrCodeX = (pdf.internal.pageSize.width - qrCodeSize) / 2 - 50;
        const qrCodeY =
            pdf.internal.pageSize.height - footerHeight + qrCodeMargin;

        // Check if there is space for the footer
        const currentY = pdf.lastAutoTable?.finalY || 0; // Assuming you use autoTable or similar
        if (currentY + footerHeight > pdf.internal.pageSize.height) {
            pdf.addPage(); // Add a new page if footer would overlap content
        }

        // Add QR code to the footer
        pdf.addImage(
            qrCodeImage,
            "PNG",
            qrCodeX,
            qrCodeY,
            qrCodeSize,
            qrCodeSize
        );

        const pdfWidth = pdf.internal.pageSize.getWidth();

        // Ajusta la posición en el eje Y, basada en la posición del QR
        const textYBase = qrCodeY + 10;

        // Configurar la fuente y tamaño
        pdf.setFontSize(10);
        pdf.setFont("helvetica", "bold");

        // Texto "Emitido por David Rincón" centrado
        pdf.text("Emitido por David Rincón", pdfWidth / 2, textYBase, {
            align: "center",
        });

        pdf.setFont("helvetica", "normal");

        // Texto "contactoweb@aza.cl" centrado
        pdf.text("contactoweb@aza.cl", pdfWidth / 2, textYBase + 5, {
            align: "center",
        });

        // Texto "ACEROS AZA S.A." centrado
        pdf.text("ACEROS AZA S.A.", pdfWidth / 2, textYBase + 10, {
            align: "center",
        });

        // Add footer lines
        const footerLines = [
            "ACERO AZA S.A.",
            "La Unión 3070, Renca, Santiago Chile • Código Postal 7464522 • Telefono: 56 26418683",
            "Planta Colina Panamericana Norte Km. 18 1/2, Colina, Santiago Chile • Fono: 56 26779500",
            "www.aza.cl",
        ];

        pdf.setFontSize(8);
        footerLines.forEach((line, index) => {
            const footerY = qrCodeY + 35 + index * 5;
            const textWidth =
            (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
            const textX = (pdf.internal.pageSize.width - textWidth) / 2;
            pdf.text(line, textX, footerY);
        });
        };

        const addTitleAndCode = (pdf) => {
        pdf.setFontSize(9.5);

        pdf.setFont("arial", "normal");
        // pdf.text('Código: ' + ofuscado, marginLeft, currentY);
        currentY += 5;
        pdf.setFont("arial", "bold");
        const title = "CERTIFICADO";
        const titleWidth =
            (pdf.getStringUnitWidth(title) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2;
        pdf.text(title, titleX, currentY);
        currentY += 1;

        pdf.line(titleX, currentY, titleX + titleWidth, currentY); // Line below title
        currentY += 10;
        pdf.setFont("arial", "normal");
        // Now add the background image starting from the current Y
        const bgStartY = currentY; // Start Y position for the background
        const bgHeight = contentHeight - bgStartY + marginTop; // Calculate the height to cover
        addBackgroundImage(bgStartY - 15, bgHeight);
        };

        const logoUrlRemote = "https://proveedores.aza.cl/react/aza.png"; // Remote logo URL
        const logoUrlLocal = "/aza.png"; // Local logo URL
        const logoWidth = 40;
        const logoHeight = 20;
        const logoX = pageWidth - logoWidth - marginLeft;

        try {
        pdf.addImage(
            logoUrlRemote,
            "PNG",
            logoX,
            marginTop,
            logoWidth,
            logoHeight
        );
        } catch (error) {
        console.error(
            "Failed to load remote logo, falling back to local logo:",
            error
        );
        try {
            pdf.addImage(
            logoUrlLocal,
            "PNG",
            logoX,
            marginTop,
            logoWidth,
            logoHeight
            );
        } catch (localError) {
            console.error("Failed to load local logo as well:", localError);
        }
        }
        currentY += logoHeight + 5;
        addTitleAndCode(pdf);

        pdf.setFont("arial", "bold");
        pdf.setFontSize(10);
        const indent = 10;

        pdf.text(
        "ACEROS AZA S.A., Rut 92.176.000-0",
        marginLeft + indent,
        currentY,
        {
            maxWidth: pageWidth - 2 * marginLeft,
        }
        );

        let boldTextWidth = pdf.getTextWidth("ACEROS AZA S.A., Rut 92.176.000-0");

        pdf.setFont("arial", "normal");

        pdf.text(
        " empresa siderúrgica, declara que en su proceso de producción",
        marginLeft + indent + boldTextWidth,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft - boldTextWidth }
        );

        currentY += 4;

        pdf.text(
        "utiliza como materia prima básicamente chatarra ferrosa que es procesada y seleccionada para ingresar posteriormente al proceso de aceración de nuestra planta ubicada en Panamericana Norte N°18.968, comuna de Colina en la ciudad de Santiago.",
        marginLeft,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );
        currentY += 16;

        pdf.setFont("arial", "bold");

        pdf.text("ACEROS AZA S.A", marginLeft + indent, currentY, {
        maxWidth: pageWidth - 2 * marginLeft,
        });

        let boldText2Width = pdf.getTextWidth("ACEROS AZA S.A ");

        pdf.setFont("arial", "normal");

        pdf.text(
        " cuenta con las autorizaciones extendidas por las SEREMI DE SALUD",
        marginLeft + indent + boldText2Width,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft - boldText2Width }
        );
        currentY += 4;

        pdf.text(
        "correspondientes para el almacenamiento y procesamiento de chatarra en nuestra planta en la Región Metropolitana y centros de reciclaje ubicados en las ciudades de Antofagasta, Concepción y Temuco cuyas resoluciones son:",
        marginLeft,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );
        currentY += 14;

        pdf.setFont("arial", "normal");

        const texts = [
        "• Región Metropolitana. Resolución N°5862, del 14 de marzo de 2000.",
        "• Antofagasta. Resolución Nº3352, del 02 de octubre de 2008.",
        "• Temuco. Resolución Nº67, del 20 de junio de 2008.",
        "• Talcahuano. Resolución Exenta N°768, del 03 de agosto de 2016.",
        ];

        texts.forEach((text) => {
        pdf.text(text, marginLeft + indent, currentY); // Imprime el texto centrado
        currentY += 6; // Espaciado entre líneas
        });

        currentY += 4; // Espaciado adicional después de la lista

        const text1 = "El Modelo de Gestión en ";
        const boldText = "ACEROS AZA S.A. ";
        const text2 = " se basa en el Premio Nacional a la Calidad y las normas";

        // Obtener ancho de los textos para alinearlos correctamente
        const text1Width = pdf.getTextWidth(text1);
        const boldText3Width = pdf.getTextWidth(boldText);

        // Posición inicial
        let x = marginLeft;
        let y = currentY;

        // Primera parte (normal)
        pdf.setFont("arial", "normal");
        pdf.text(text1, x + indent, y);

        // Segunda parte (negrita)
        pdf.setFont("arial", "bold");
        pdf.text(boldText, x + text1Width + indent, y);

        // Tercera parte (normal)
        pdf.setFont("arial", "normal");
        pdf.text(text2, x + text1Width + boldText3Width + indent, y, {
        maxWidth: pageWidth - 2 * marginLeft - (text1Width + boldText3Width),
        align: "justify",
        });

        currentY += 4;

        pdf.text(
        "ISO 9002, ISO 14001 Y OHSAS 18001. Este Modelo y las normas proveen el marco para el mejoramiento continuo y el aseguramiento de todos nuestros sistemas productivos.",
        marginLeft,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );
        currentY += 12;

        pdf.setFont("arial", "bold");
        pdf.text(tableData[0][10] + " RUT: " + tableData[0][11],
        marginLeft + indent,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );

        let boldText4Width = pdf.getTextWidth(
        `${tableData[0][10]} RUT: ${tableData[0][11]}`
        );
        pdf.setFont("arial", "normal");

        pdf.text(
        " , Domicilio RAMON ",
        marginLeft + indent + boldText4Width,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft - boldText4Width }
        );
        currentY += 4;

        pdf.text(
        `SUBERCASEAUX 1268 SAN MIGUEL, Es proveedor en nuestra empresa ACEROS AZA S.A. a través del código interno `,
        marginLeft,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );

        const text2Width = pdf.getTextWidth("del código interno ");

        pdf.setFont("arial", "bold");
        pdf.text(
        `N.º ${tableData[0][12]}, `,
        marginLeft + text2Width,
        currentY + 4,
        {
            maxWidth: pageWidth - 2 * marginLeft - text2Width,
            align: "justify",
        }
        );

        const text5Width = pdf.getTextWidth(`N.º ${tableData[0][12]},`);

        pdf.setFont("arial", "normal");
        pdf.text(
        " del sistema informático SAP.",
        marginLeft + text2Width + text5Width,
        currentY + 4,
        {
            maxWidth: pageWidth - 2 * marginLeft - text5Width,
            align: "justify",
        }
        );

        currentY += 12;

        pdf.setFont("arial", "normal");

        pdf.text(
        "A petición del proveedor y para ser presentado ante",
        marginLeft + indent,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );

        const text3Width = pdf.getTextWidth(
        "A petición del proveedor y para ser presentado ante "
        );

        pdf.setFont("arial", "bold");
        pdf.text(
        `${tableData[0][14]}. RUT:`,
        marginLeft + indent + text3Width,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft - text3Width, align: "justify" }
        );
        currentY += 4;

        pdf.setFont("arial", "bold");

        pdf.text(`${tableData[0][13]}, `, marginLeft, currentY, {
        maxWidth: pageWidth - 2 * marginLeft,
        align: "justify",
        });

        const text4Width = pdf.getTextWidth(`${tableData[0][13]}, `);

        pdf.setFont("arial", "normal");
        pdf.text(
        "Certificamos la siguiente entrega de chatarra ferrosa en planta:",
        marginLeft + text4Width,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft - text4Width, align: "justify" }
        );

        const text6Width = pdf.getTextWidth(
        "Certificamos la siguiente entrega de chatarra ferrosa en planta: "
        );

        pdf.setFont("arial", "bold");

        pdf.text(
        `${tableData[0][9]}.`,
        marginLeft + text4Width + text6Width,
        currentY,
        {
            maxWidth: pageWidth - 2 * marginLeft - text4Width - text6Width,
            align: "justify",
        }
        );

        currentY += 8;

        pdf.setFont("arial", "bold");

        pdf.text(
        "Este documento se emite para efectos y fines estrictamente comerciales.",
        marginLeft + indent,
        currentY,
        { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
        );

        currentY += 10;

        pdf.autoTable({
        startY: currentY,
        head: [
            [
            "OC",
            "TICKET",
            "NOMBRE TRANSPORTISTA",
            "RUT TRANSPORTISTA",
            "PATENTE",
            "#GUÍA",
            "PESO",
            "UNIDAD",
            "FECHA",
            ],
        ],
        body: [...tableData],
        margin: { left: 20 },
        theme: "grid",
        styles: { fontSize: 8 },
        headStyles: { fontSize: 8 },
        });

        if (fechaSolicitud) {
        const formattedDate = moment(fechaSolicitud, "YYYY-MM-DD HH:mm:ss").format("D [de] MMMM [del] YYYY");
        pdf.setFont("arial", "normal");
        pdf.setFontSize(10);
        pdf.text(`Santiago, ${formattedDate}`, pageWidth - marginLeft - 10, currentY + 60, { align: "right" });
        }

        await addPageFooter(pdf);

        const pdfData = pdf.output("bloburl");
        window.open(pdfData);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="pages-body login-page flex flex-column" style={{ background: "none", backgroundColor: "#007ad9" }}>
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <h1 className="pages-detail">Portal Proveedores</h1>
                    <img src="assets/layout/images/aza.png" alt="" style={cardStyles.imagen}/>
                    {estado != "" && (
                        <Button id="print" onClick={() => generateImprovedCertificadoPDF(codigo)} style={{marginTop: "32px", textAlign: "center"}} label="descargar certificado"/>
                    )}
                </div>
            </div>
        </div>
    );

}

export default PDF;