import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toolbar } from "primereact/toolbar";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import moment from "moment"; // Import Moment.js
import "moment/locale/es"; // Import Spanish locale
import { useGlobalContext } from "../Config";
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode, FilterOperator } from "primereact/api";

// Helper Functions
const formatRut = (rut) => {
  if (!rut) return "";
  const cleanRut = rut.replace(/[^0-9kK]/g, "");
  const body = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1).toUpperCase();
  const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, "");
  return `${formattedBody}-${dv}`;
};

const isValidRUT = (rut) => {
  if (!rut || typeof rut !== "string") return false;
  const cleanRut = rut.replace(/[^0-9kK]/g, "");
  if (cleanRut.length < 2) return false;
  const body = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1).toUpperCase();
  let sum = 0;
  let multiplier = 2;
  for (let i = body.length - 1; i >= 0; i--) {
    sum += multiplier * parseInt(body[i], 10);
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }
  const expectedDV = 11 - (sum % 11);
  return (
    (expectedDV === 10
      ? "K"
      : expectedDV === 11
      ? "0"
      : expectedDV.toString()) === dv
  );
};

const formatDateToDDMMYYYY = (date) => {
  console.log("date", date);
  if (!date) return ""; // Handle cases where date might be null or undefined
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

const SolicitudCertificados = () => {
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filtersBusq, setFiltersBusq] = useState({
    fromDate: null,
    toDate: null,
    rut: "",
    ticketNumber: "",
    razonSocial: "",
    sapProveedor: "",
  });

  const [destinatario, setDestinatario] = useState({
    rut: "",
    razonSocial: "",
  });

  const [errors, setErrors] = useState({
    filtersBusq: { rut: "" },
    destinatario: { rut: "", razonSocial: "" },
  });
  const clearVariables = () => {
    setDestinatario({ rut: "", razonSocial: "" });
    setErrors({});
    setTickets([]);
    setSelectedTickets([]);
  };
  const handleRutChange = (value, fieldName, section) => {
    // Allow only numbers and 'k' or 'K'
    const sanitizedValue = value.replace(/[^0-9kK]+/g, "");
    const formattedRut = formatRut(sanitizedValue);
    const isValid = isValidRUT(formattedRut);
    const newErrors = {
      ...errors[section],
      rut: isValid ? "" : "RUT incorrecto. Revise el formato.",
    };

    if (section === "filters") {
      setFiltersBusq({ ...filtersBusq, [fieldName]: formattedRut });
      setErrors({ ...errors, filtersBusq: newErrors });
    } else if (section === "destinatario") {
      setDestinatario({ ...destinatario, [fieldName]: formattedRut });
      setErrors({ ...errors, destinatario: newErrors });
    }
  };

  const handleSearch = async () => {
    const { fromDate, toDate, rut, razonSocial, ticketNumber, sapProveedor } =
      filtersBusq;

    if (
      !ticketNumber &&
      !fromDate &&
      !toDate &&
      !rut &&
      !razonSocial &&
      !sapProveedor
    ) {
      alert(
        "Debe ingresar al menos un filtro: fechas, número de ticket o datos del proveedor."
      );
      return;
    }

    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      alert("Debe ingresar ambas fechas (Fecha Desde y Fecha Hasta).");
      return;
    }
    if (rut && !isValidRUT(rut)) {
      setErrors((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          rut: "RUT incorrecto. Revise el formato.",
        },
      }));
      alert("RUT incorrecto. Revise el formato.");
      return;
    }
    setErrors((prev) => ({
      ...prev,
      filters: { ...prev.filters, rut: "" },
    }));
    setTickets([]);
    setSelectedTickets([]);
    try {
      setLoading(true);
      // Format filters for API request
      const params = new URLSearchParams({
        desde: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
        hasta: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
        numeroTicket: ticketNumber || "",
        rutProveedor: rut || "",
        razonSocialProveedor: razonSocial || "",
        sapProveedor: sapProveedor || "",
      });

      // API Call
      const response = await fetch(
        `${baseUrl}getTicketsCertificadosByFiltros?${params}`,
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching tickets");
      }

      const data = await response.json();
      setTickets(data.ticketCertificados);
      console.log("Tickets fetched successfully:", data);
    } catch (error) {
      console.error("Error fetching tickets:", error);
      alert("Error fetching tickets. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  /*const handleGenerate = async () => {
    if (!destinatario || !destinatario?.rut || !destinatario?.razonSocial) {
      alert("Complete todos los campos del destinatario.");
      return;
    }
    // Check if there are selected tickets
    if (selectedTickets?.length === 0) {
      alert("No ha seleccionado tickets.");
      return;
    }
    const proveedorSet = new Set(
      selectedTickets.map((ticket) => ticket.proveedor)
    );
    if (proveedorSet.size > 1) {
      alert("Todos los tickets seleccionados deben tener el mismo proveedor.");
      return;
    }
    if (errors?.destinatario?.rut || !destinatario?.razonSocial) {
      const errorMessages = [];

      if (errors?.destinatario?.rut) {
        errorMessages.push("El RUT es incorrecto.");
      }
      if (!destinatario?.razonSocial) {
        errorMessages.push("Razón social requerida.");
      }

      // Set errors
      setErrors((prev) => ({
        ...prev,
        destinatario: {
          ...prev.destinatario,
          razonSocial: destinatario.razonSocial
            ? ""
            : "Razón social requerida.",
        },
      }));

      // Show alert with error messages
      alert(errorMessages.join(" "));
      return;
    }

    const params = new URLSearchParams({
      destinatario: JSON.stringify(destinatario), // Convert to JSON string
      tickets: JSON.stringify(selectedTickets), // Convert to JSON string
    });

    try {
      setLoading(true);
      // Call the API
      const response = await fetch(
        `${baseUrl}generarCertificado?idProveedor=${
          selectedTickets[0]?.idProveedor
        }&idSolicitante=${sessionStorage.getItem(
          "idUsuario"
        )}&${params.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert("error:", errorData.men);
        return;
      }
      clearVariables();
      alert("Certificados generados exitosamente.");
    } catch (error) {
      console.error("An error occurred while generating certificates:", error);
    } finally {
      setLoading(false);
    }
  };*/

  const handleGenerate = async () => {
    if (!destinatario || !destinatario?.rut || !destinatario?.razonSocial) {
      alert("Complete todos los campos del destinatario.");
      return;
    }

    if (selectedTickets?.length === 0) {
      alert("No ha seleccionado tickets.");
      return;
    }

    const proveedorSet = new Set(
      selectedTickets.map((ticket) => ticket.proveedor)
    );
    if (proveedorSet.size > 1) {
      alert("Todos los tickets seleccionados deben tener el mismo proveedor.");
      return;
    }

    if (errors?.destinatario?.rut || !destinatario?.razonSocial) {
      const errorMessages = [];
      if (errors?.destinatario?.rut)
        errorMessages.push("El RUT es incorrecto.");
      if (!destinatario?.razonSocial)
        errorMessages.push("Razón social requerida.");

      setErrors((prev) => ({
        ...prev,
        destinatario: {
          ...prev.destinatario,
          razonSocial: destinatario.razonSocial
            ? ""
            : "Razón social requerida.",
        },
      }));

      alert(errorMessages.join(" "));
      return;
    }

    // Crear objeto con los datos
    const requestData = {
      destinatario,
      idProveedor: Number(selectedTickets[0]?.idProveedor),
      idSolicitante: Number(sessionStorage.getItem("idUsuario")),
      tickets: selectedTickets,
    };

    try {
      setLoading(true);
      // Llamada a la API con los datos en el `body`
      const response = await fetch(`${baseUrl}generarCertificado`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      if (!response.ok) {
        alert(`Error: ${responseData.mensaje}`);
        return;
      }

      clearVariables();
      alert("Certificados generados exitosamente.");
    } catch (error) {
      console.error("Ocurrió un error al generar los certificados:", error);
      alert("Error al generar los certificados. Intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const generateImprovedCertificadoPDF = async (idCertificado, estado, fechaSolicitud) => {
    window.open("https://proveedores.aza.cl/certificado/" + idCertificado);
  };

  const generateImprovedCertificadoPDF0 = async (idCertificado, estado) => {
    const pdf = new jsPDF("p", "mm", "a4");

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const marginLeft = 30;
    const marginTop = 15;
    const footerHeight = 70; // Fixed footer height
    const contentHeight = pageHeight - marginTop - footerHeight; // Height available for content
    let currentY = marginTop; // Start content position

    let tableData = [];
    let background = await loadBackgroundImage();

    try {
      setLoading(true);
      const response = await fetch(
        `${baseUrl}getDataPDFByIdCertificado?idCertificado=${idCertificado}`,
        {
          method: "GET",
          credentials: "include",
          headers: headerText,
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching tickets");
      }
      const data = await response.json();
      if (data && data.certificados && data.certificados.length > 0) {
        const getValueOrDefault = (value, defaultValue = "No info") => {
          return (typeof value === "string" || typeof value === "number") &&
            value !== null
            ? value
            : defaultValue;
        };

        tableData = data.certificados.map((item) => [
          getValueOrDefault(item.oc),
          getValueOrDefault(item.numero_ticket),
          getValueOrDefault(item.nombre_transportista),
          getValueOrDefault(item.rut_transportista, "Falta RUT"),
          getValueOrDefault(item.patente),
          getValueOrDefault(item.guia),
          getValueOrDefault(item.peso),
          getValueOrDefault(item.unidad),
          item.fecha ? formatDateToDDMMYYYY(item.fecha) : "No info",
          getValueOrDefault(item.planta),
          getValueOrDefault(item.proveedor_razon_social),
          getValueOrDefault(item.proveedor_rut),
          getValueOrDefault(item.proveedor_codigo_sap),
          getValueOrDefault(item.rut_destinatario),
          getValueOrDefault(item.razon_social_destinatario),
        ]);
      } else {
        alert("Este certificado no tiene registros");
        return;
      }
    } catch (error) {
      alert(`Error al solicitar registros del certificado: ${error.message}`);
      return;
    } finally {
      setLoading(false);
    }
    async function loadBackgroundImage() {
      const logoUrlRemote = "https://proveedores.aza.cl/react/logoFondoAza.png"; // Remote logo URL
      const logoUrlLocal = "/logoFondoAza.png"; // Local logo URL

      async function fetchImage(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }

      try {
        return await fetchImage(logoUrlRemote);
      } catch (error) {
        console.error(
          "Failed to load remote logo, falling back to local logo:",
          error
        );
        try {
          return await fetchImage(logoUrlLocal);
        } catch (localError) {
          console.error("Failed to load local logo as well:", localError);
          throw new Error("Failed to load any logo image."); // Re-throw or handle accordingly
        }
      }
    }

    // Function to add background image to a page
    function addBackgroundImage(startY, height) {
      if (estado === "APROBADO") {
        const imageWidth = pageWidth - marginLeft - 30;
        pdf.addImage(background, "PNG", marginLeft, startY, imageWidth, height);
      } else {
        const watermarkText =
          estado === "SOLICITADO" ? "SOLICITADO" : "RECHAZADO";

        const textX = pageWidth / 2;
        const textY = pageHeight / 2;

        pdf.saveGraphicsState();

        if (watermarkText === "SOLICITADO") {
          pdf.setTextColor(169, 169, 169); // Gray
        } else {
          pdf.setTextColor(255, 0, 0);
        }
        pdf.setFontSize(60);
        pdf.setFont("helvetica", "bold");

        // Add estado watermark diagonally
        pdf.text(watermarkText, textX + 20, textY + 10, {
          angle: 45, // Rotate diagonally
          align: "center",
        });

        pdf.restoreGraphicsState();
      }
    }

    const addPageFooter = async (pdf) => {
      const qrCodeData = "https://www.aza.cl";
      const qrCodeImage = await QRCode.toDataURL(qrCodeData, { width: 100 });
      const qrCodeSize = 30; // Size of the QR code
      const qrCodeMargin = 10; // Margin around the QR code

      // Define the position for the footer
      const qrCodeX = (pdf.internal.pageSize.width - qrCodeSize) / 2 - 50;
      const qrCodeY =
        pdf.internal.pageSize.height - footerHeight + qrCodeMargin;

      // Check if there is space for the footer
      const currentY = pdf.lastAutoTable?.finalY || 0; // Assuming you use autoTable or similar
      if (currentY + footerHeight > pdf.internal.pageSize.height) {
        pdf.addPage(); // Add a new page if footer would overlap content
      }

      // Add QR code to the footer
      pdf.addImage(
        qrCodeImage,
        "PNG",
        qrCodeX,
        qrCodeY,
        qrCodeSize,
        qrCodeSize
      );

      pdf.text("Validador de", qrCodeX + 15, qrCodeY - 3, {align: "center"});
      pdf.text("Autenticidad", qrCodeX + 15, qrCodeY + 1, {align: "center"});

      /* Add footer text
      const marginLeft = 30;
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      pdf.text("Emitido por David Rincón", marginLeft + 53, qrCodeY + 10);
      pdf.setFont("helvetica", "normal");
      pdf.text("contactoweb@aza.cl", marginLeft + 58, qrCodeY + 15);
      pdf.text("ACEROS AZA S.A.", marginLeft + 60, qrCodeY + 20);*/

      const pdfWidth = pdf.internal.pageSize.getWidth();

      // Ajusta la posición en el eje Y, basada en la posición del QR
      const textYBase = qrCodeY + 10;

      // Configurar la fuente y tamaño
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");

      // Texto "Emitido por David Rincón" centrado
      pdf.text("Emitido por David Rincón", pdfWidth / 2, textYBase, {
        align: "center",
      });

      pdf.setFont("helvetica", "normal");

      // Texto "contactoweb@aza.cl" centrado
      pdf.text("contactoweb@aza.cl", pdfWidth / 2, textYBase + 5, {
        align: "center",
      });

      // Texto "ACEROS AZA S.A." centrado
      pdf.text("ACEROS AZA S.A.", pdfWidth / 2, textYBase + 10, {
        align: "center",
      });

      // Add footer lines
      const footerLines = [
        "ACERO AZA S.A.",
        "La Unión 3070, Renca, Santiago Chile • Código Postal 7464522 • Telefono: 56 26418683",
        "Planta Colina Panamericana Norte Km. 18 1/2, Colina, Santiago Chile • Fono: 56 26779500",
        "www.aza.cl",
      ];

      pdf.setFontSize(8);
      footerLines.forEach((line, index) => {
        const footerY = qrCodeY + 35 + index * 5;
        const textWidth =
          (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const textX = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(line, textX, footerY);
      });
    };

    const addTitleAndCode = (pdf) => {
      pdf.setFontSize(9.5);

      pdf.setFont("arial", "normal");
      pdf.text(`Código: ${idCertificado}`, marginLeft, currentY);
      currentY += 5;
      pdf.setFont("arial", "bold");
      const title = "CERTIFICADO";
      const titleWidth =
        (pdf.getStringUnitWidth(title) * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      pdf.text(title, titleX, currentY);
      currentY += 1;

      pdf.line(titleX, currentY, titleX + titleWidth, currentY); // Line below title
      currentY += 10;
      pdf.setFont("arial", "normal");
      // Now add the background image starting from the current Y
      const bgStartY = currentY; // Start Y position for the background
      const bgHeight = contentHeight - bgStartY + marginTop; // Calculate the height to cover
      addBackgroundImage(bgStartY - 15, bgHeight);
    };

    /*const addParagraphs = (paragraphs, pdf) => {
      pdf.setFontSize(10);
      const groupedParagraphs = [];
      let currentGroup = [];
      const sangria = 20; // Indentation for the first line
      let wasGroupedParagraph = false; // Flag to track if the last paragraph was grouped

      // List of exact grouped paragraphs
      const groupedParagraphsList = [
        "Región Metropolitana. Resolución N°5862, del 14 de marzo de 2000.",
        "Antofagasta. Resolución Nº3352, del 02 de octubre de 2008.",
        "Temuco. Resolución Nº67, del 20 de junio de 2008.",
        "Talcahuano. Resolución Exenta N°768, del 03 de agosto de 2016.",
      ];

      // Loop through paragraphs to group specific lines
      paragraphs.forEach((paragraph) => {
        if (groupedParagraphsList.includes(paragraph)) {
          currentGroup.push(paragraph); // Add to the current group
        } else {
          if (currentGroup.length > 0) {
            groupedParagraphs.push(currentGroup.join("\n")); // Join the grouped lines
            currentGroup = [];
          }
          groupedParagraphs.push(paragraph); // Add non-grouped paragraphs
        }
      });

      // Add any remaining group
      if (currentGroup.length > 0) {
        groupedParagraphs.push(currentGroup.join("\n"));
      }

      // Add paragraphs to PDF
      groupedParagraphs.forEach((paragraph) => {
        const paragraphLines = paragraph.split("\n");
        const isGroupedParagraph = paragraphLines.every((line) =>
          groupedParagraphsList.includes(line.trim())
        );

        const fullWidth = pageWidth - 2 * marginLeft; // Available width for normal lines
        const indentedWidth = fullWidth - sangria; // Available width for indented lines

        // Determine if the paragraph needs indentation
        const splitText = isGroupedParagraph
          ? pdf.splitTextToSize(paragraph, fullWidth) // No indentation for grouped paragraphs
          : (() => {
              // Handle indentation for non-grouped paragraphs
              const firstLineSplit = pdf.splitTextToSize(
                paragraph,
                indentedWidth
              );
              const firstLine = firstLineSplit.shift(); // Extract the first line
              const remainingLines = pdf.splitTextToSize(
                firstLineSplit.join(" "),
                fullWidth
              );
              return [firstLine, ...remainingLines];
            })();

        const requiredHeight = splitText.length * 6;

        if (currentY + requiredHeight > contentHeight) {
          addPageFooter(pdf);
          pdf.addPage();
          currentY = marginTop; // Reset currentY for new page
        }

        // Add spacing if transitioning from grouped to non-grouped
        if (!isGroupedParagraph && wasGroupedParagraph) {
          currentY += 5; // Add spacing after grouped paragraphs
        }

        // Render paragraph with appropriate formatting
        splitText.forEach((line, index) => {
          if (isGroupedParagraph) {
            // No indentation for grouped paragraphs; justified alignment
            pdf.text(line, marginLeft, currentY, { align: "justify" });
          } else if (index === 0) {
            // Add indentation to the first line of non-grouped paragraphs
            pdf.text(line, marginLeft + sangria, currentY, {
              align: "justify",
            });
          } else {
            // Normal alignment for subsequent lines of non-grouped paragraphs
            pdf.text(line, marginLeft, currentY, { align: "justify" });
          }
          currentY += 5; // Increment for line height
        });

        if (!isGroupedParagraph) {
          currentY += 5; // Add spacing between non-grouped paragraphs
        }

        // Update the flag for the next iteration
        wasGroupedParagraph = isGroupedParagraph;
      });
    };*/

    const logoUrlRemote = "https://proveedores.aza.cl/react/aza.png"; // Remote logo URL
    const logoUrlLocal = "/aza.png"; // Local logo URL
    const logoWidth = 40;
    const logoHeight = 20;
    const logoX = pageWidth - logoWidth - marginLeft;

    try {
      pdf.addImage(
        logoUrlRemote,
        "PNG",
        logoX,
        marginTop,
        logoWidth,
        logoHeight
      );
    } catch (error) {
      console.error(
        "Failed to load remote logo, falling back to local logo:",
        error
      );
      try {
        pdf.addImage(
          logoUrlLocal,
          "PNG",
          logoX,
          marginTop,
          logoWidth,
          logoHeight
        );
      } catch (localError) {
        console.error("Failed to load local logo as well:", localError);
      }
    }
    currentY += logoHeight + 5;
    addTitleAndCode(pdf);

    /*const paragraphs = [
      "ACEROS AZA S. A., Rut 92.176.000-0 empresa siderúrgica, declara que en su proceso de producción utiliza como materia prima básicamente chatarra ferrosa que es procesada y seleccionada para ingresar posteriormente al proceso de aceración de nuestra planta ubicada en Panamericana Norte N°18.968. comuna de Colina en la ciudad de Santiago.",
      "ACEROS AZA S. A cuenta con las autorizaciones extendidas por las SEREMI DE SALUD correspondientes para el almacenamiento y procesamiento de chatarra en nuestra planta en la Región Metropolitana y centros de reciclaje ubicados en las ciudades de Antofagasta, Concepción y Temuco. Las resoluciones asociadas son:",
      "Región Metropolitana. Resolución N°5862, del 14 de marzo de 2000.",
      "Antofagasta. Resolución Nº3352, del 02 de octubre de 2008.",
      "Temuco. Resolución Nº67, del 20 de junio de 2008.",
      "Talcahuano. Resolución Exenta N°768, del 03 de agosto de 2016.",
      "El Modelo de Gestión en ACEROS AZA S.A. se basa en el Premio Nacional a la Calidad y las normas ISO 9002. ISO 14001 Y OHSAS 18001. Este Modelo y las normas proveen el marco para la mejora continua y el aseguramiento de todos nuestros sistemas productivos.",
      `${tableData[0][10]} RUT: ${tableData[0][11]}. Domicilio RAMON SUBERCASEAUX 1268 SAN MIGUEL, es proveedor en nuestra empresa ACEROS AZA S.A a través del código interno N.º ${tableData[0][12]}. del sistema informático SAP.`,
      `A petición del proveedor y para ser presentado ante, ${tableData[0][14]}. RUT: ${tableData[0][13]}, Certificamos la siguiente entrega de chatarra ferrosa en planta: ${tableData[0][9]}. Este documento se emite para efectos y fines estrictamente comerciales.`,
    ];*/

    //addParagraphs(paragraphs, pdf);

    pdf.setFont("arial", "bold");
    pdf.setFontSize(10);
    const indent = 10;

    pdf.text(
      "ACEROS AZA S.A., Rut 92.176.000-0",
      marginLeft + indent,
      currentY,
      {
        maxWidth: pageWidth - 2 * marginLeft,
      }
    );

    let boldTextWidth = pdf.getTextWidth("ACEROS AZA S.A., Rut 92.176.000-0");

    pdf.setFont("arial", "normal");

    pdf.text(
      " empresa siderúrgica, declara que en su proceso de producción",
      marginLeft + indent + boldTextWidth,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldTextWidth }
    );

    currentY += 4;

    pdf.text(
      "utiliza como materia prima básicamente chatarra ferrosa que es procesada y seleccionada para ingresar posteriormente al proceso de aceración de nuestra planta ubicada en Panamericana Norte N°18.968, comuna de Colina en la ciudad de Santiago.",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 16;

    pdf.setFont("arial", "bold");

    pdf.text("ACEROS AZA S.A", marginLeft + indent, currentY, {
      maxWidth: pageWidth - 2 * marginLeft,
    });

    let boldText2Width = pdf.getTextWidth("ACEROS AZA S.A ");

    pdf.setFont("arial", "normal");

    pdf.text(
      " cuenta con las autorizaciones extendidas por las SEREMI DE SALUD",
      marginLeft + indent + boldText2Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldText2Width }
    );
    currentY += 4;

    pdf.text(
      "correspondientes para el almacenamiento y procesamiento de chatarra en nuestra planta en la Región Metropolitana y centros de reciclaje ubicados en las ciudades de Antofagasta, Concepción y Temuco cuyas resoluciones son:",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 14;

    pdf.setFont("arial", "normal");

    const texts = [
      "• Región Metropolitana. Resolución N°5862, del 14 de marzo de 2000.",
      "• Antofagasta. Resolución Nº3352, del 02 de octubre de 2008.",
      "• Temuco. Resolución Nº67, del 20 de junio de 2008.",
      "• Talcahuano. Resolución Exenta N°768, del 03 de agosto de 2016.",
    ];

    texts.forEach((text) => {
      pdf.text(text, marginLeft + indent, currentY); // Imprime el texto centrado
      currentY += 6; // Espaciado entre líneas
    });

    currentY += 4; // Espaciado adicional después de la lista

    const text1 = "El Modelo de Gestión en ";
    const boldText = "ACEROS AZA S.A. ";
    const text2 = " se basa en el Premio Nacional a la Calidad y las normas";

    // Obtener ancho de los textos para alinearlos correctamente
    const text1Width = pdf.getTextWidth(text1);
    const boldText3Width = pdf.getTextWidth(boldText);

    // Posición inicial
    let x = marginLeft;
    let y = currentY;

    // Primera parte (normal)
    pdf.setFont("arial", "normal");
    pdf.text(text1, x + indent, y);

    // Segunda parte (negrita)
    pdf.setFont("arial", "bold");
    pdf.text(boldText, x + text1Width + indent, y);

    // Tercera parte (normal)
    pdf.setFont("arial", "normal");
    pdf.text(text2, x + text1Width + boldText3Width + indent, y, {
      maxWidth: pageWidth - 2 * marginLeft - (text1Width + boldText3Width),
      align: "justify",
    });

    currentY += 4;

    pdf.text(
      "ISO 9002, ISO 14001 Y OHSAS 18001. Este Modelo y las normas proveen el marco para el mejoramiento continuo y el aseguramiento de todos nuestros sistemas productivos.",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 12;

    pdf.setFont("arial", "bold");
    pdf.text(
      `${tableData[0][10]} RUT: ${tableData[0][11]} `,
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    let boldText4Width = pdf.getTextWidth(
      `${tableData[0][10]} RUT: ${tableData[0][11]}`
    );
    pdf.setFont("arial", "normal");

    pdf.text(
      " ,  es proveedor de nuestra empresa ",
      marginLeft + indent + boldText4Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldText4Width }
    );
    currentY += 4;

    pdf.text(
      `ACEROS AZA S.A. a través del código interno `,
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    const text2Width = pdf.getTextWidth("del código interno ");

    pdf.setFont("arial", "bold");
    pdf.text(
      `N.º ${tableData[0][12]}, `,
      marginLeft + text2Width,
      currentY + 4,
      {
        maxWidth: pageWidth - 2 * marginLeft - text2Width,
        align: "justify",
      }
    );

    const text5Width = pdf.getTextWidth(`N.º ${tableData[0][12]},`);

    pdf.setFont("arial", "normal");
    pdf.text(
      " del sistema informático SAP.",
      marginLeft + text2Width + text5Width,
      currentY + 4,
      {
        maxWidth: pageWidth - 2 * marginLeft - text5Width,
        align: "justify",
      }
    );

    currentY += 12;

    pdf.setFont("arial", "normal");

    pdf.text(
      "A petición del proveedor y para ser presentado ante",
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    const text3Width = pdf.getTextWidth(
      "A petición del proveedor y para ser presentado ante "
    );

    pdf.setFont("arial", "bold");
    pdf.text(
      `${tableData[0][14]}. RUT:`,
      marginLeft + indent + text3Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - text3Width, align: "justify" }
    );
    currentY += 4;

    pdf.setFont("arial", "bold");

    pdf.text(`${tableData[0][13]}, `, marginLeft, currentY, {
      maxWidth: pageWidth - 2 * marginLeft,
      align: "justify",
    });

    const text4Width = pdf.getTextWidth(`${tableData[0][13]}, `);

    pdf.setFont("arial", "normal");
    pdf.text(
      "Certificamos la siguiente entrega de chatarra ferrosa en planta:",
      marginLeft + text4Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - text4Width, align: "justify" }
    );

    const text6Width = pdf.getTextWidth(
      "Certificamos la siguiente entrega de chatarra ferrosa en planta: "
    );

    pdf.setFont("arial", "bold");

    pdf.text(
      `${tableData[0][9]}.`,
      marginLeft + text4Width + text6Width,
      currentY,
      {
        maxWidth: pageWidth - 2 * marginLeft - text4Width - text6Width,
        align: "justify",
      }
    );

    currentY += 8;

    pdf.setFont("arial", "bold");

    pdf.text(
      "Este documento se emite para efectos y fines estrictamente comerciales.",
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    currentY += 10;

    pdf.autoTable({
      startY: currentY,
      head: [
        [
          "OC",
          "TICKET",
          "NOMBRE TRANSPORTISTA",
          "RUT TRANSPORTISTA",
          "PATENTE",
          "#GUÍA",
          "PESO",
          "UNIDAD",
          "FECHA",
        ],
      ],
      body: [...tableData],
      margin: { left: 20 },
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fontSize: 8 },
    });

    await addPageFooter(pdf);

    const pdfData = pdf.output("bloburl");
    window.open(pdfData);
  };

  const renderCertificateButton = (rowData) => (
    <Button
      label="Ver Certificado"
      icon="pi pi-eye"
      className="p-button-sm p-button-success"
      disabled={!rowData.certificado || rowData.certificado === 0}
      style={{
        transition: "transform 0.3s ease",
      }}
      onClick={() =>
        generateImprovedCertificadoPDF(
          rowData.certificado,
          rowData.estadoCertificado
        )
      }
    />
  );
  const handleTicketNumberChange = (value) => {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    setFiltersBusq({ ...filtersBusq, ticketNumber: sanitizedValue });
  };
  const formatChileanRUT = (rut) => {
    if (!rut) return "";

    let cleanRut = rut.replace(/\./g, "").replace(/-/g, "").toUpperCase();

    if (cleanRut.length < 2) return cleanRut;

    let body = cleanRut.slice(0, -1);
    let dv = cleanRut.slice(-1);

    body = body.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `${body}-${dv}`;
  };
  const normalizeRUT = (rut) =>
    rut.replace(/\./g, "").replace(/-/g, "").toUpperCase();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filtersHeader, setFiltersHeader] = useState(null);
  useEffect(() => {
    initFilters();
  }, []);
  const initFilters = () => {
    setFiltersHeader({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rutProveedor: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filtersHeader };
    _filters["global"].value = value;

    setFiltersHeader(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Buscar Rut Proveedor"
          />
        </span>
        <span className="p-input-icon-left"></span>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <div className="p-4">
      {/* Header Toolbar */}
      <Toolbar
        className="mb-4"
        left={() => (
          <h3 style={{ transform: "translateY(0)", transition: "all 0.8s" }}>
            Solicitud Certificados
          </h3>
        )}
      />

      {/* Filters */}
      <Card className="mb-4 shadow-3">
        <div className="p-fluid grid">
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="fromDate">Fecha Desde</label>
            <Calendar
              id="fromDate"
              value={filtersBusq.fromDate}
              onChange={(e) =>
                setFiltersBusq({ ...filtersBusq, fromDate: e.value })
              }
              showIcon
              dateFormat="dd/mm/yy"
              placeholder="Seleccione fecha de inicio"
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="toDate">Fecha Hasta</label>
            <Calendar
              id="toDate"
              value={filtersBusq.toDate}
              onChange={(e) =>
                setFiltersBusq({ ...filtersBusq, toDate: e.value })
              }
              showIcon
              dateFormat="dd/mm/yy"
              placeholder="Seleccione fecha de término"
              disabled={loading}
            />
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="rut">RUT Proveedor</label>
            <InputText
              id="rut"
              value={filtersBusq?.rut}
              onChange={(e) =>
                handleRutChange(e.target.value, "rut", "filters")
              }
              placeholder="Ej: 12345678-9"
              className={errors?.filters?.rut ? "p-invalid" : ""}
              disabled={loading}
            />

            {errors?.filters?.rut && (
              <small className="p-error">{errors?.filters?.rut}</small>
            )}
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="ticketNumber">Número Ticket</label>
            <InputText
              id="ticketNumber"
              value={filtersBusq.ticketNumber}
              onChange={(e) => handleTicketNumberChange(e.target.value)}
              placeholder="Ingrese número de ticket"
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label htmlFor="razonSocial">Razón Social Proveedor</label>
            <InputText
              id="razonSocial"
              value={filtersBusq.razonSocial}
              onChange={(e) =>
                setFiltersBusq({ ...filtersBusq, razonSocial: e.target.value })
              }
              placeholder="Ingrese la razón social del proveedor"
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label htmlFor="sapProveedor">SAP Proveedor</label>
            <InputText
              id="sapProveedor"
              value={filtersBusq.sapProveedor}
              onChange={(e) =>
                setFiltersBusq({ ...filtersBusq, sapProveedor: e.target.value })
              }
              placeholder="Ingrese SAP Proveedor"
              disabled={loading}
            />
          </div>

          <div className="col-12 md:col-6 lg:col-8">
            <Button
              label="Buscar Ticket"
              icon="pi pi-search"
              className="p-button-success w-full mt-3"
              onClick={handleSearch}
              style={{ backgroundColor: "rgb(0, 122, 217)" }}
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <Button
              label="Limpiar Filtros"
              icon="pi pi-eraser"
              className="p-button-success w-full mt-3"
              onClick={() => {
                setFiltersBusq({
                  ...filtersBusq,
                  fromDate: null,
                  toDate: null,
                  rut: "",
                  ticketNumber: "",
                  razonSocial: "",
                });
                setErrors({ ...errors, filters: { rut: "" } });
              }}
              disabled={loading}
            />
          </div>
        </div>
      </Card>

      <Divider />

      {/* Tickets Table */}
      <Card title="Tickets" className="mb-4 shadow-3">
        {/* Ticket Count */}
        <div className="mb-2">
          <span className="text-lg font-bold">{tickets.length} </span>
          ticket{tickets.length !== 1 ? "s" : ""} encontrado
          {tickets.length !== 1 ? "s" : ""}
        </div>

        <DataTable
          value={tickets}
          dataKey="idDetalle"
          className="p-datatable-gridlines"
          rowClassName={(rowData) =>
            rowData.certificado !== 0 && rowData.certificado !== null
              ? "p-disabled-row"
              : ""
          }
          rows={15}
          paginator
          emptyMessage="No se encontraron tickets"
          loading={loading}
          globalFilterFields={["rutProveedor"]}
          header={header}
          filters={filtersHeader}
        >
          <Column
            header={() => {
              // ✅ Get selectable tickets (no certificado)
              const selectableTickets = tickets.filter(
                (ticket) => !ticket.certificado
              );

              // ✅ Find the first available ticket to determine the proveedor
              const firstAvailableTicket =
                selectableTickets.length > 0 ? selectableTickets[0] : null;
              const selectedProveedor = firstAvailableTicket
                ? firstAvailableTicket.proveedor
                : null;

              // ✅ Filter only tickets with the same proveedor
              const sameProveedorTickets = selectableTickets.filter(
                (ticket) => ticket.proveedor === selectedProveedor
              );

              // ✅ Check if all same proveedor tickets are selected
              const isAllSelected =
                selectedTickets.length > 0 &&
                selectedTickets.length === sameProveedorTickets.length;

              // ✅ Check if some but not all are selected (for indeterminate state)
              const isSomeSelected =
                selectedTickets.length > 0 &&
                selectedTickets.length < sameProveedorTickets.length;

              return (
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    if (isChecked) {
                      if (sameProveedorTickets.length === 0) {
                        alert("No hay tickets disponibles para seleccionar.");
                        return;
                      }

                      setSelectedTickets(sameProveedorTickets);
                    } else {
                      setSelectedTickets([]);
                    }
                  }}
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                />
              );
            }}
            body={(rowData) => (
              <Checkbox
                type="checkbox"
                checked={selectedTickets.some(
                  (ticket) => ticket.idDetalle === rowData.idDetalle
                )}
                disabled={!!rowData.certificado}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setSelectedTickets((prev) => {
                    if (isChecked) {
                      if (
                        prev.length > 0 &&
                        prev[0].proveedor !== rowData.proveedor
                      ) {
                        alert(
                          "Solo puede seleccionar tickets con el mismo proveedor."
                        );
                        return prev;
                      }
                      return [...prev, rowData];
                    } else {
                      return prev.filter(
                        (ticket) => ticket.idDetalle !== rowData.idDetalle
                      );
                    }
                  });
                }}
              />
            )}
          />

          <Column field="numeroTicket" header="Ticket" sortable />
          <Column
            field="fechaCreacion"
            header="Fecha"
            body={(rowData) => formatDateToDDMMYYYY(rowData.fechaCreacion)}
            sortable
          />
          <Column field="numeroGuia" header="Guía" sortable />
          <Column field="descripcion" header="Descripción" sortable />
          <Column field="pesoNeto" header="Peso" sortable />
          <Column field="unidad" header="Unidad Medida" sortable />
          <Column field="proveedor" header="Proveedor" sortable />
          <Column
            field="rutProveedor"
            header="RUT Proveedor"
            sortable
            body={(rowData) =>
              rowData.rutProveedor ? rowData.rutProveedor : "No tiene rut"
            }
          />

          <Column body={renderCertificateButton} />
        </DataTable>
      </Card>

      <Divider />

      <Card title="Datos Destinatario" className="shadow-3">
        <div className="p-fluid grid">
          <div className="col-12 md:col-6">
            <label htmlFor="rutDestinatario">RUT Destinatario</label>
            <InputText
              id="rutDestinatario"
              value={destinatario?.rut}
              onChange={(e) =>
                handleRutChange(e.target.value, "rut", "destinatario")
              }
              placeholder="Ej: 12345678-9"
              className={errors?.destinatario?.rut ? "p-invalid" : ""}
              disabled={loading}
            />
            {errors?.destinatario?.rut && (
              <small className="p-error">{errors?.destinatario?.rut}</small>
            )}
          </div>
          <div className="col-12 md:col-6">
            <label htmlFor="razonSocialDestinatario">
              Razón Social Destinatario
            </label>
            <InputText
              id="razonSocialDestinatario"
              value={destinatario?.razonSocial}
              onChange={(e) =>
                setDestinatario({
                  ...destinatario,
                  razonSocial: e.target.value,
                })
              }
              className={errors?.destinatario?.razonSocial ? "p-invalid" : ""}
              disabled={loading}
            />
            {errors?.destinatario?.razonSocial && (
              <small className="p-error">
                {errors?.destinatario?.razonSocial}
              </small>
            )}
          </div>
        </div>
        <div className="flex justify-content-end gap-2 mt-4">
          <Button
            label="Generar Solicitud"
            icon="pi pi-file"
            className="p-button-primary"
            onClick={handleGenerate}
            disabled={
              errors?.destinatario?.rut || !destinatario?.razonSocial || loading
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default SolicitudCertificados;
